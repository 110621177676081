@charset "utf-8";

@import "_setting.css";
@import "_html5reset.css";
@import "_mixin.css";
@import "_font.css";
@import "_sp.css";
@import "_pc.css";
@import "_print.css";

* {
  box-sizing: border-box;
}
