@media print {

  .site-footercontacts ,
  .site-pagefooter-sns ,
  .site-pagefooter-navis-primary ,
  .site-pagefooter-navis-secondary-links-pages ,
  .site-pageheader-spnavi
  {
    display: none;
  }

  .site-pageheader {
    background-color: transparent;
    color: #000;

    .is-darkheader  & {
      background-color: transparent;
      color: #000;
    }
  }
  .is-darkheader .site-pageheader-logo-text svg ,
  .site-pageheader-logo-text svg {
    fill: #000;
  }


  .site-pagefooter-navis {
    background-color: transparent;
    padding: 0;
  }

  .site-pagefooter-navis-secondary {
    margin-top: 0;
    align-items: center;
  }

  .site-pagefooter-copyright {
    padding: 0;
  }

}
