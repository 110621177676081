
  @mixin m-middle {
  html {
     font-size: 62.5%;
  }
  body {
    font-size: 16px;
    font-size: 1.6rem;
    min-width: var(--base-pc-width);
  }

  a {
    transition: color var(--base-animespeed);
  }
  .for-pc {
    display: block;
  }
  .for-sp {
    display: none;
  }
  .site {

    /* 共通ヘッダー ================================================ */
    &-pageheader {
      background-color: var(--header-bgcolor);
      border-bottom: 1px solid var(--header-bordercolor);
      height: 80px;
      min-width: var(--base-pc-width);
      transition: background-color var(--base-animaspeed);


      .is-darkheader & {
        background-color: var(--header-bgcolor-secondary);
        border-bottom: none;
      }

      &:hover {
        background-color: var(--header-bgcolor);
        border-bottom: 1px solid var(--line-primary);
      }

      &-inner {
        @mixin m-flexset;
        align-items: center;
        @mixin m-inner;
      }

      &-logo {
        @mixin m-flexset;
        align-items: center;
        padding: 0;
        height: 80px;

        &-mark {
          margin-right: 10px;

          img {
            width: 40px;
            height: 40px;
          }
        }

        &-text {

          svg {
            width: 142px;
            height: 28px;

            .site-pageheader:hover & {
              fill: var(--color-primary);
            }

          }
        }
      }

      /* モデルボタン ================================================ */
      &-spnavi {

        @mixin m-flexset;

        &-ul {

          &-li {

            &-a {
              position: relative;
              padding: 0 30px 0 13px;
              height: 80px;
              @mixin m-flexset;
              align-items: center;
              border-bottom: none;
              font-size: 1.4rem;
              letter-spacing: -.05em;

              .is-darkheader & {
                color: var(--header-textcolor-secondary);

                &::before {
                  background-image: url('../image/icon-arrow-dwon-red-bkdark.svg');
                }
              }

              .site-pageheader:hover & {
                color: var(--header-textcolor);

                &::before {
                  background-image: url('../image/icon-arrow-dwon-red.svg');
                }
              }

              &::before {
                content: '';
                width: 30px;
                height: 30px;
                position: absolute;
                top: 30%;
                right: 0;
                background: url('../image/icon-arrow-dwon-red.svg') center center no-repeat;
                background-size: 10px 5px;
                transition: transform var(--base-animespeed-slow);
              }

              &::after {
                content: '';
                width: 0;
                height: 2px;
                background-color: var(--color-primary);
                transition: width var(--base-animespeed-slow);
                position: absolute;
                left:0;
                bottom: 0;

              }

              &.is-open , &:hover {
                text-decoration: none;

                &::before {
                  background-image: url('../image/icon-arrow-dwon-red.svg');
                  transform: rotate(180deg);
                }

                &::after {
                  width: 100%;

                  .site-pageheader:hover & {
                    background-color: var(--color-primary);
                  }
                }
              }

              &.is-active {
                &::after {
                  width: 100%;
                  .is-darkheader & {
                    background-color: var(--color-primary);
                  }

                  .site-pageheader:hover & {
                    background-color: var(--color-primary);
                  }
                }
              }
            }
          }
        }

        &-models {
          display: none;
          position: absolute;
          width: 100%;
          left:0;
          top: 80px;
          background-color: rgba(var(--header-dropdown-bgcolor));
          height: auto;
          overflow: hidden;
          padding: 28px 0;
          z-index: 9999;

          &-inner {
            width: 871px;
            margin: 0 auto;

            @mixin m-flexset flex-start , wrap;

          }

          &-model {
            border-bottom: none;
            padding: 0;

            @mixin m-flexbase 255px;
            margin-left: 30px;

            /*&:nth-child(4){
              margin-left: 0;
            }*/

            a {
              display: block;
              width: 255px;
              padding: 30px 0;
              position: relative;

              &:hover {
                text-decoration: none;
                color: var(--button-text-hover);

                  &::before {
                    width: 100%;
                  }
              }

              &::before {
                content: '';
                width: 0;
                height: 100%;
                position: absolute;
                top:0;
                left:0;
                background-color: var(--color-primary);
                transition: width var(--base-animespeed);
                z-index: 0;
              }
            }

            &-category {
              font-size: 1.2rem;
              position: relative;
              z-index: 10;
            }
            &-image {
              position: relative;
              z-index: 10;

              img {
                width: 255px;
              }
            }
            &-name {
              font-size: 1.6rem;
              font-family: 'sequel';
              position: relative;
              z-index: 10;
			  .new{
				  color: var(--color-primary)!important;
				  transition: all var(--base-animespeed);

	              a:hover & {
	                color: var(--button-text-hover);
	              }
			  }

            }
            &-new {
              transition: all var(--base-animespeed);

              a:hover & {
                color: var(--button-text-hover);
              }

            }
            &-kana {
              font-size: 1.2rem;
              position: relative;
              z-index: 10;
              line-height: 1;

            }

            &-price {
              margin-top: 10px;
              position: relative;
              z-index: 10;


              &-yen {
                font-size: 1.4rem;
              }

              &-discon {
                font-size: 1.2rem;
              }
            }

            &-btn {
              margin-top: 9px;
              width: 140px;
              @mixin m-more-btn-small var(--color-primary) ,var(--color-primary),var(--header-textcolor-secondary);
              position: relative;
              z-index: 10;
              font-size: 1.2rem;

              span {
                font-family: var(--font-apex-book);
                letter-spacing: .1em;
              }

            }

          }

        }


      }

      &-btn {
        display: none;
      }


      /* ヘッダーナビ ================================================ */
      &-mainnavi {
        @mixin m-flexset;
        align-items: center;
        position: relative;
        width: auto;
        background-color: inherit;
        height: 80px;
        padding: 0 0;
        overflow: visible;


        &-ul {
          @mixin m-flexset;
          align-items: center;

          &-li {
            position: relative;
            z-index: 999;


          }
        }

        &-category {
          background-color: inherit;
          padding: 0 30px 0 13px;
          height: 80px;
          cursor: pointer;
          display: block;
          margin-bottom: 0;
          font-size: 1.4rem;
          letter-spacing: -.05em;

          display: flex;
          align-items: center;

          span {
            display: none;
          }



          .is-darkheader & {
            color: var(--header-textcolor-secondary);

            &::before {
              background-image: url('../image/icon-arrow-dwon-red-bkdark.svg');
            }
          }

          .site-pageheader:hover & {
            color: var(--header-textcolor);

            &::before {
              background-image: url('../image/icon-arrow-dwon-red.svg');
            }

          }

          &::before {
            content: '';
            width: 30px;
            height: 30px;
            position: absolute;
            top: 30%;
            right: 0;
            background: url('../image/icon-arrow-dwon-red.svg') center center no-repeat;
            background-size: 10px 5px;
            transition: transform var(--base-animespeed-slow);
          }

          &::after {
            content: '';
            width: 0;
            height: 2px;
            background-color: var(--color-primary);
            transition: width var(--base-animespeed-slow);
            position: absolute;
            left:0;
            bottom: 0;

          }

          &.is-open , &:hover {
            text-decoration: none;

            &::before {
              background-image: url('../image/icon-arrow-dwon-red.svg');
              transform: rotate(180deg);
            }

            &::after {

              width: 100%;
            }
          }
          &.is-active {
            &::after {
              width: 100%;

              .is-darkheader & {
                background-color: var(--color-primary);
              }

              .site-pageheader:hover & {
                background-color: var(--color-primary);
              }



            }
          }
        }
        &-occont {
          display: none;
          padding: 40px;
          position: absolute;
          top: 100%;
          left: 0;
          width: 375px;
          z-index: 9999;
          background-color: rgba(var(--header-dropdown-bgcolor));


          &.is-closed {
            display: none;
          }

          > li {
            border-top: 1px solid var(--line-primary);

            &:first-child {
              border-top: 1px solid var(--line-primary);
            }

            &:last-child {
              border-bottom: 1px solid var(--line-primary);
            }

          }

          a {
            padding: 15px 0;
            position: relative;

            &::before {
              content: '';
              width: 30px;
              height: 30px;
              position: absolute;
              top: 50%;
              right: -3%;
              margin-top: -15px;
              background: url('../image/icon-arrow-dwon-red.svg') center center no-repeat;
              background-size: 10px 5px;
              transform: rotate(-90deg);

            }

            &:hover {
              text-decoration: none;
              color: var(--color-primary);
            }
          }
          &-description {
            font-size: 12px;
          }
        }
        &-wide {
          width: 430px;
        }
        /* その他リンク ================================================ */
        &-links {
          margin-top: 0;
          margin-left: 15px;
          padding: 0;

          &-ul {

            li {
              border-bottom: none;
            }
            a {
              display: block;
              padding: 0;
              position: relative;
              height: 80px;
              font-size: 1.4rem;
              display: flex;
              align-items: center;
              letter-spacing: -.05em;

              .is-darkheader & {
                color: var(--header-textcolor-secondary);
              }

              .site-pageheader:hover & {
                color: var(--header-textcolor);
              }

              .site-pageheader:hover &:hover {
                color: var(--color-primary);

                svg {
                  fill: var(--color-primary);
                }
              }

              &::before {
                display: none;
              }
              svg {
                fill: var(--header-textcolor);
                width: 12px;
                height: 21px;
                margin-right: calc(10/375*100)vw;
                transform: translate(0,0);
                margin-right: 5px;

                .is-darkheader & {
                  fill: var(--header-textcolor-secondary);
                }

                .site-pageheader:hover & {
                  fill: var(--header-textcolor);
                }
              }

            }
          }
        }

        /* 検索窓 ================================================ */
        &-searchbox {
          margin-top: 0;
          padding: 0;
          margin-left: 15px;
          padding-left: 15px;
          border-left: 1px solid var(--line-primary);
          height: 28px;

          transition: all var(--base-animespeed-slow);

          &.is-open {
            position: fixed;
            width: 100%;
            background-color: var(--bgcolor-primary);
            top: 0;
            left: 0;
            padding: 0;
            margin: 0;
            height: 80px;
            z-index: 999;
            border-left: none;
          }

          &-inner {
            border-bottom: none;

            .is-open & {
              @mixin m-inner;
            }
          }

          &-inputbox {
            display: none;
            padding: 0;
            border: none;
            border-radius: 0;
            outline: none;
            background: none;
            font-size: 2.1rem;
            width: 100%;
            padding: 28px 50px;

            .is-open & {
              display: block;
            }
          }

          &-searchicon {
            fill: var(--header-textcolor);
            width: 19px;
            height: 19px;
            transform: translate(0,28%);
            cursor: pointer;
            left: 40px;
            top: 30%;
            position: static;

            .is-darkheader & {
              fill: var(--header-textcolor-secondary);
            }

            .site-pageheader:hover & {
              fill: var(--header-textcolor);
            }

            .is-open & {
              fill: var(--header-textcolor);
              position: absolute;
            }

            .site-pageheader:hover &:hover {
              fill: var(--color-primary);
            }

          }

          &-closeicon {
            stroke: var(--color-primary);
            width: 24px;
            height: 24px;
            display: none;
            transition: transform var(--base-animespeed);

            .is-open & {
              display: block;
              position: absolute;
              cursor: pointer;
              top: 30px;
              right: 20px;
            }

            &:hover {
              transform: rotate(-90deg);
            }


          }
        }
      }
    }

    /* サーチボックス PC ============================= */
    &-searchheadcloser {
      display: block;
      position: absolute;
      width: 100%;
      height: 80px;
      top: -80px;
      left: 0;
      z-index: 1888;
      background-color: var(--header-bgcolor);
      border-bottom: 1px solid var(--line-primary);


      &.is-open {
        top: 0;
      }

      &.is-close {
        animation: searchboxhide var(--base-animespeed) ease  var(--base-animespeed) forwards;
      }

      &-inner {
        @mixin m-inner;
        position: relative;
      }

      &-searchicon {
        fill: var(--header-textcolor);
        width: 19px;
        height: 19px;
        top: 30px;
        position: absolute;
        right: 980px;

        .is-close & {
          animation: searchicontoright var(--base-animespeed) ease;
        }

      }
    }
    &-searchheader {
      display: block;
      position: absolute;
      width: 100%;
      height: 80px;
      top: -80px;
      left: 0;
      z-index: 1999;
      background-color: var(--header-bgcolor);
      border-bottom: 1px solid var(--line-primary);

      &.is-open {
        top: 0;
      }

      &-inner {
        @mixin m-inner;
        position: relative;
      }


      &-searchicon {
        fill: var(--color-primary);
        width: 19px;
        height: 19px;
        top: 30px;
        position: absolute;
        right: 20px;
        transition: all var(--base-animespeed);

        .is-open & {
            animation: searchicontoleft var(--base-animespeed) ease forwards;
          fill: var(--header-textcolor);
        }

      }
      &-inputbox {
        padding: 0;
        border: none;
        border-radius: 0;
        outline: none;
        background: none;
        font-size: 2.1rem;
        width: 100%;
        padding: 28px 50px;
        transition: all var(--base-animespeed);
        opacity: 0;

        .is-open & {
          opacity: 1;
        }

      }
      &-closeicon {
        stroke: var(--color-primary);
        width: 24px;
        height: 24px;
        transition: all var(--base-animespeed);
        position: absolute;
        cursor: pointer;
        top: 30px;
        right: 20px;
        opacity: 0;

        &:hover {
          transform: rotate(-90deg);
        }

        .is-open & {
          opacity: 1;
        }


      }

    }


    /*共通フッター ================================================ */
    &-pagefooter {

      &-sns {
        &-inner {
          @mixin m-inner;
          padding: 40px var(--base-pc-padding);
        }
        &-ul {
	        padding: 0;

          &-li {
            margin: 0 20px;

            &-a {
              width: 28px;
              height: 28px;

              &.facebook {
                background-size: 28px;

                &:hover {
                  background-image: url(../image/icon-facebook-active@2x.png);
                }

              }

              &.twitter {
                background-size: 28px 23px;

                &:hover {
                  background-image: url(../image/icon-twitter-active@2x.png);
                }

              }

              &.youtube {
                background-size: 31px 22px;
                width: 31px;
                margin-top: 0;

                &:hover {
                  background-image: url(../image/icon-youtube-active@2x.png);
                }

              }

              &.instagram {
                background-size: 28px;

                &:hover {
                  background-image: url(../image/icon-instagram-active@2x.png);
                }

              }

              &.line {
                background-size: 28px;

                &:hover {
                  background-image: url(../image/icon-line-active@2x.png);
                }

              }
              
              &.mail {
                background-size: 31px auto;
                width: 31px;
                margin-top: 0;

                &:hover {
                  background-image: url(../image/icon-mail-active@2x.png);
                }

              }
            }
          }
        }

      }

      &-navis {
        padding: 60px 0 65px;

        &-inner {
          @mixin m-inner;
        }


        &-primary {

          @mixin m-flexset;

          &-group {
            border-bottom: none;

          }

          &-title {
            font-size: 1.4rem;
            padding: 0;
            cursor: auto;

            &::before {
              display: none;
            }

          }

          &-occont {
            display: block;
            list-style: none;
            margin-top: 25px;

            li {
              border-top: none;
              font-size:  1.4rem;
              margin-top: 15px;

              a {
                padding: 0;
                display: block;

                &::before {
                  display: none;
                }

                &:hover {
                  color: var(--footer-text-hover);
                }
              }
            }
          }
        }

        &-secondary {
          margin-top: 70px;
          @mixin m-flexset;
          flex-direction: column;


          a {
            color: var(--footer-text-secondary);
            &:hover {
              text-decoration: none;
              color: var(--footer-text-hover);
            }
          }

          &-links {
            margin-top: 0;
            flex: 1;
            @mixin m-flexset;
            flex-direction: column;

            &-pages {
	          position: relative;
	          width: 100%;
	          padding-left: 70px;
              columns: 1;
              font-size: 12px;
              border-top: 1px solid var(--text-secondary);
              padding-top: 10px;
              margin-top: 30px;
              flex:1;

              &-ul {
                list-style: none;
                @mixin m-flexset flex-end;

                li {
                  margin-top: 0;
                  margin-left: 30px;

                  &:first-child {
                    margin-top: 0;
                  }
                }
              }

            }

            &-tel {
              font-size: 18px;
              margin-bottom: 0;
              @mixin m-flexset flex-start,nowrap;
              align-items: flex-start;
              flex-direction: row;
              p{
	              font-size: 1.4rem;
	              &.tel-title{
		              margin-bottom: 18px;
	              }
              }
              .tel-flex{
	              @mixin m-flexset flex-start,nowrap;
	              align-items: flex-start;
	              margin-bottom: 6px;
	              flex-direction: row;
	              img{
		              width: 82px;
		              height: auto;
		              margin-right: 12px;
	              }
              }
              .tel-box{
	              width: auto;
	              padding: 0;
	              border-bottom: none;
	              &:first-child{
		            margin-right: 50px;
		            small {
		                font-family: var(--base-font);
		                font-size: 1rem;
		                margin-top: 6px;
		                margin-bottom: 0;
					}
					img{
						margin-right: 12px;
					}
	              }
	              &:nth-child(2){
		              a{
			              font-size: 1.2rem;
			              height: 35px;
			              width: 120px;
		              }
		            small {
		                font-family: var(--base-font);
		                font-size: 1.2rem;
		                line-height: 1.8rem;
		                margin-right: 12px;
		                margin-bottom: 0;
					}
	              }
              }
            }
          }

          &-logo {
	        position: absolute;
	        top: 10px;
	        left: 0;
            margin-top: 0;
            margin-right: 0;
            text-align: center;
			&.for-pc{
				display: block;
			}
			&.fir-sp{
				display: none;
			}
            a {
              svg {
                width: 60px;
                height: 60px;
                fill: var(--footer-text-secondary);
                transition: fill var(--base-animespeed-slow);

              }

              &:hover {
                svg {
                  fill: var(--footer-text-hover);
                }
              }
            }
          }

        }
      }


      &-copyright {
        font-size: 10px;
        text-align: right;
        padding: 34px 0 40px;

      }

      &-topagetop {

        &-btn {
          width: 60px;
          height: 60px;
          right: 60px;
          bottom: 120%;
          transition: all var(--base-animespeed);

          &:hover {
            background-color: var(--color-primary);
          }

          svg {
            width: 16px;
            height: 29px;

          }

        }

      }

      &-topagetop {

        &-btn {
          right: 0;


      }

      }
    }

    /* フッターコンタクト ================================================ */
    &-footercontacts {
      position: fixed;
      width: 375px;
      bottom: -150px;
      right: 0;

      &.is-shown {
        bottom: 0;
      }

      &-inner {
        @mixin m-flexset;
        list-style: none;
        align-items: baseline;
        padding: 0 10px;

        a {
          color: var(--text-tertiary);
          display: block;
          font-size: 1rem;
          font-weight: bold;
          text-align: center;
          padding: 10px 0 12px;

          svg {
            fill: var(--text-tertiary);
            width: 24px;
            height: 24px;
            margin-bottom: 8px;
            transition: fill var(--base-animespeed);
          }

          &:hover {
            color: var(--footer-text-hover);
            text-decoration: none;

            svg {
              fill: var(--footer-text-hover);
            }

          }
        }
      }

      &-li {
        @mixin m-flexbase calc(90/375*100vw);

      }
    }

  }


  /* ユーティリティ郡 ================================================ */
  .u {
    &-width780 {
      width: 780px;
      margin: 0 auto;
      padding: 0;
    }
    /* モデルバナー（トップページ仕様） */

    &-models {
      &-title {
        font-size: 4.2rem;
      }
      &-list {
        margin-top: 30px;
        @mixin m-flexset flex-start, wrap;
      }
      &-item {
        @mixin m-flexbase 33.3333%;
        &-link {
          display: block;
          position: relative;
        }

        &-image {
          line-height: 0;
          position: relative;
          width: 100%;
          height: 62%;
          overflow: hidden;

          &::before {
            content: '';
            height: 100%;
            width: 0;
            background-color: var(--color-hoverbg);
            transition: width var(--base-animespeed) var(--animate-easeinout);
            mix-blend-mode: multiply;
            position: absolute;
            top:0;
            left:0;
            a:hover & {
              width: 100%;
            }
          }

          img {
            transition: opacity var(--base-animespeed);
          }
        }

        &-text {
          padding: 20px;

          &-type {
            font-size: 1.2rem;

          }

          &-model {
            font-size: 2.4rem;
            margin-top: 10px;
            .new:after {
              a:hover & {
                color: #fff;
              }
            }
          }

          &-catch {
            display: none;
            line-height: 1.375;
            margin-top: 10px;

            a:hover & {
              display: block;

            }
          }

          &-discon {
            font-size: 1.2rem;
            margin-left: 15px;

          }

          &-spec {
            display: none;
            line-height: 1.375;
            margin-top: 10px;
            font-size: .9rem;

            sup {
              font-size: .9rem;
              vertical-align: super;
            }

            &-number {
              font-size: 2.8rem;
              font-size: 28px;
              font-family: var(--font-apex-book);
            }
            &-unit {
              font-size: 1.4rem;
              font-family: var(--font-apex-book);
            }

            &-bar {
              display: inline-block;
              width: 1px;
              background-color: var(--button-text-hover);
              height: 28px;
              margin: 0 10px;
            }



            a:hover & {
              display: block;

            }
          }
          &-note {
            font-size: .9rem;
          }

          &-btn {
            @mixin m-more-btn-small var(--button-text-hover) , var(--color-primary), var(--button-text-hover);
            font-size: 1.2rem;
            width: 140px;
            margin-top: 5px;

            span {
              font-family: var(--font-apex-book);
              letter-spacing: .1em;
            }

          }
        }
      }
    }
  }
  /* カルーセル ================================================ */
  .u-carousel-kv {
    height: calc(100vh - 80px);
    max-height: 800px;

    &-single {
      height: calc(100vh - 80px);
      max-height: 800px;
      background-size: cover;
      &.float-top {
        background-position: center bottom;
      }
      &.float-center {
        background-position: center center;
      }
      &.float-center-left {
        background-position: left center;
      }
      &.float-top-right {
        background-position: right bottom;
      }
      &.type-white,
      &.type-white2 {
        &:after {
          display: none;
        }
      }
    }

    &-text {
      position: absolute;
      bottom: 15%;
      left:0;
      width: 100%;
      padding: 0;

      &-inner {
        @mixin m-inner;
      }
    }

    &-link {
      color: var(--header-textcolor-secondary);
      display: block;

      &:hover {
        text-decoration: none;
      }
      .type-white2 & {
        color: var(--header-textcolor-secondary);
      }
    }

    &-catch {
      font-size: 4.4rem;
      .type-white2 & {
        text-shadow: 1px 1px 2px black;
      }
    }

    &-lead {
      margin-top: 10px;
      font-size: 2.2rem;
      .type-white2 & {
        text-shadow: 1px 1px 2px black;
      }
    }

    &-btn {
      display: block;
      margin-top: 25px;
      transform: translate(0 , 260%);
      transition: transform 2s 1.2s , opacity 2s 1.2s;
      opacity: 0;

      @mixin m-more-btn-small var(--button-text-hover) ,var(--color-primary),var(--button-text-hover);
      width: 140px;
      font-size: 1.2rem;

      span {
        font-family: var(--font-apex-book);
        letter-spacing: .1em;
      }

      .is-active & {
        transform: translate(0 , 0);
        opacity: 1;
      }

      /* .type-white & {
        @mixin m-more-btn-small var(--color-primary),var(--color-primary),var(--button-text-hover);
      } */
    }

  }


  .u-prev-arrow {
    padding: 35px 14px;
    left: 5%;
    bottom:inherit;
    top: 50%;
    margin-top: -50px;
    cursor: pointer;
    transition: all var(--base-animespeed) var(--animate-easeinout);

    svg {
      transform: rotate(180deg);
      width: 16px;
      height: 29px;
    }

    &:hover {
      background-color: rgba(var(--bgcolor-ovarlay-light));

    }
  }

  .u-next-arrow {
    padding: 35px 14px;
    position: absolute;
    right: 5%;
    bottom:inherit;
    top: 50%;
    margin-top: -50px;
    cursor: pointer;
    transition: all var(--base-animespeed) var(--animate-easeinout);

    svg {
      width: 16px;
      height: 29px;
    }

    &:hover {
      background-color: rgba(var(--bgcolor-ovarlay-light));

    }
  }

  .slick-dots {
    padding: 40px;

    &>li {
      width: 14px;
      margin: 0 4px;
    }

    svg {
      width: 14px;
      height: 14px;

    }
  }

  .u-kvcover {
    position: relative;
  }
  .u-kvscroll {
    display: block;
    position: absolute;
    color: var(--bgcolor-primary);
    bottom: 60px;
    right: 10%;
    font-family: var(--font-apex-book);
    font-size: 14px;
    width: 140px;
    height: 20px;
    overflow: hidden;
    transform: rotate(90deg);

    &-top {
      right: 50%;
      transform: translateX(620px)  rotate(90deg);
    }

    a {
      display: block;
      color: var(--bgcolor-primary);

    }


    &-text {
      position: absolute;
      left: 0%;
      top: 20%;
      animation: textanime 2s linear infinite;
      letter-spacing: .3em;
    }

    &::before {
      content: '';
      width: 50%;
      height: 1px;
      background-color: var(--bgcolor-primary);
      position: absolute;
      top: 45%;
      left: -60%;
      animation: llineanime 2s linear infinite;
    }

    &::after {
      content: '';
      width: 100%;
      height: 1px;
      background-color: var(--bgcolor-primary);
      position: absolute;
      top: 45%;
      left: 25%;
      animation: rlineanime 2s linear infinite;
    }


  }

  /* ニュース ================================================ */
  .u-news {

    &-article {
      @mixin m-flexset flex-start;
      margin-top: -20px;

    }

    &-content {
      border-bottom: none;
      margin-top: 60px;
      padding-bottom: 0;
      margin-left: 20px;
      @mixin m-flexbase 235px;

      &:first-child {
        margin-left: 0;
      }

      &-link {
        height: 100%;
        @mixin m-flexset;
        flex-direction: column;

        &:hover {
          text-decoration: none;

          .u-news-content-imagebox::before {
            width: 100%;
          }
        }

      }

      &-main {
        display: block;

      }

      &-imagebox {
        @mixin m-flexbase 235px;
        height: 235px;
        margin-right: 0;
        line-height: 0;
        position: relative;

        &::before {
          content: '';
          height: 235px;
          width: 0;
          background-color: var(--color-hoverbg);
          transition: width var(--base-animespeed) var(--animate-easeinout);
          position: absolute;
          z-index: 1;
          mix-blend-mode: multiply;
        }
        img {
          position: absolute;
          top: 0;
          left: 0;
          transition: all var(--base-animespeed);
          mix-blend-mode: multiply;
        }
        span {
          font-size: 1.2rem;
          color: #fff;
          right: 10px;
          bottom: 33px;
        }

      }

      &-textbox {
        background-color: var(--bgcolor-primary);
        margin: -30px 0 0 20px;
        width: 215px;
        z-index: 9;
        position: relative;
        padding: 15px 0 0 20px;

        &-tags {

          &-tag {
            font-size: .9rem;
            border: 1px solid var(--text-primary);
            border-radius: 3px;
            padding: 5px 10px 1px;
            margin-top: 5px;
          }
        }

        &-text {
          margin-top: 10px;
          font-size: 1.8rem;
          line-height: 1.555555556;

          a:hover & {
            color: var(--color-primary);
          }
        }

        &-date {
          font-size: 1.2rem;
          margin-top: 12px;
        }
      }

      &-btncover {
        text-align: left;
        padding-left: 40px;
      }

      &-btn {
        @mixin m-more-btn-small var(--color-primary), var(--button-text-hover), var(--color-primary);
        margin-top: 10px;
        width: 140px;
        font-size: 12px;

        span {
          font-family: var(--font-apex-book);
          letter-spacing: .1em;
        }
      }
    }
  }


  /* 動画 ================================================ */

  .u-movies {
    margin-top: 40px;

    &-article {
      @mixin m-flexset;
	  justify-content: center;
    }

    &-content {
      @mixin m-flexbase 320px;
	  margin: 0 10px;
      &-imagebox {
        position: relative;
        height: 208px;

        &::before {
          content: '';
          width: 0;
          height: 100%;
          position: absolute;
          top:0;
          left:0;
          background-color: var(--color-hoverbg);
          transition: width var(--base-animespeed) var(--animate-easeinout);
          z-index: 2;
          mix-blend-mode: multiply;

          a:hover & {
            width: 100%;
          }
        }

        img {
          width: 100%;
          transition: opacity var(--base-animespeed);
          z-index: 1;
          position: absolute;
          mix-blend-mode: multiply;

        }

        &::after {
          background-size: 51px;
          width: 51px;
          height: 51px;
          margin: -25px 0 0 -25px;
          position: absolute;
          z-index: 10;

        }
      }

      &-textbox {
        background-color: var(--bgcolor-primary);
        margin: -20px 0 0 auto;
        padding: 20px 0 0 20px;
        width: 278px;
        position: relative;
        z-index: 9;

        &-tags {

          &-tag {
            font-size: .9rem;
            border-radius: 3px;
            padding: 5px 10px 1px;
            margin-top: 5px;
          }
        }

        &-text {
          font-size: 1.8rem;
          margin-top: 10px;

          a:hover & {
            color: var(--color-primary);
          }
        }
      }

      &-btncover {
        text-align: left;
        padding-left: 60px;
      }

      &-btn {
        @mixin m-more-btn-small var(--color-primary), var(--button-text-hover), var(--color-primary);
        margin-top: 15px;
        width: 140px;
        font-size: 1.2rem;

        span {
          font-family: var(--font-apex-book);
          letter-spacing: .1em;
        }
      }
    }
  }

  /* 通常ページのカバー ==================================== */
  .u-normal-cover {
    @mixin m-inner;
    margin-bottom: 80px;
    a {
      transition: color var(--base-animespeed);
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .u-default-container {
    @mixin m-inner;
    a {
      &:hover {
        text-decoration: none;
      }
    }
  }
  /* パンくず ==================================== */
  .u-pankuzu,
  .u-breadcrumb {
    font-size: 1.2rem;
    margin-top: 10px;
    overflow: hidden;
    z-index: 100;
    ul {
      li {
        &::before {
          margin: 0 5px;

        }
      }
    }
  }
  .u-breadcrumb {
    @mixin m-inner;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
  }

  /* ページタイトル ==================================== */
  .u-pagetitle {
    margin-top: 30px;

    &-text {
      font-size: 4.2rem;
      line-height: 1.19047619;
    }

  }

  /* ページ内囲み ==================================== */
  .u-pagecontent {
    margin-top: 80px;
  }

  /* 利用規約などテキストのみのページスタイル ==================================== */
  .u-textcontent {

    h2,h3,h4 {
      margin-top: 6em;
    }
    p {
      margin-top: 1.5em;
    }
  }

  /* 一般的なページスタイル ==================================== */
  .u-basiccontent {

    h2 {
      font-size: 3.2rem;
      line-height: 1.1875;
    }

    h3 {
      font-size: 2.6rem;
      line-height: 1.384615385;
    }

    h4 {
      font-size: 2.2rem;
      line-height: 1.3;
    }

  }

  /* テーブルデザイン  */
  .u-table-style-primary {
    font-size: 1.2rem;
    min-width: inherit;

    thead {
      th {
        padding: 15px 15px 15px 0;
        font-weight: bold;
      }
    }

    tbody {
      th {
        padding: 8px 8px 8px 0;
      }
      td {
        padding: 8px 8px 8px 0;
      }
    }

  }

  /* アンカーナビ =====================*/
  .u-unckernavi {
    display: block;

    ul {
      @mixin m-flexset flex-start;

    }

    li {
      margin-right: 60px;
    }

    a {
      color: var(--text-primary);
      display: block;
      background: url(../image/icon-arrow-dwon-red.svg) center 85% no-repeat;
      padding: 0 0 15px;
      transition: all var(--base-animespeed);

      &:hover {
        text-decoration: none;
        background-position: center 100% !important;
      }

    }

  }

  /* プロダクト系 ================================================ */

  .u-product {

    .u-kvscroll {
      right: -100px;
      line-height: 1;
    }

    &-undercont {
      margin-bottom: 160px;
    }

    &-kv {
      height: calc(100vh - 80px);
      max-height: 800px;
      margin: 0 auto;
      background-size: cover;

      &-inner {
        @mixin m-inner;
        height: calc(100vh - 80px);
        max-height: 800px;
        position: relative;
      }

      &-catch {
        display: block;
        position: absolute;
      }
      &-banner {
        @mixin m-absolute 12%,auto,auto,0;
        @mixin m-flexset center,wrap;
        padding: .7em 0;
        font-size: 2rem;
        width: 400px;
        &-title {
          font-size: 3.6rem;
          width: 100%;
        }
        &-title2 {
          font-size: 3rem;
          width: 100%;
          margin-bottom: 25px;
          &:after {
            width: 57px;
          }
        }
        &-period {
          font-size: 1.8rem;
          width: 100%;
          span {
            font-size: 3.2rem;
          }
          big {
            font-size: 3.6rem;
          }
        }
        &-lead {
          font-size: 1.9rem;
          margin-top: 5px;

          big {
            font-size: 3.7rem;
          }

          &:before {
            width: 57px;
          }
        }
        &-button {
          @mixin m-more-btn-small #fff,var(--color-primary),#fff;
          width: 120px;
          font-size: 1.2rem;
        }
        &-wrap {
          @mixin m-inner;
          @mixin m-absolute auto,auto,82px,50%;
          @mixin m-flexset space-between,no-wrap;
          transform: translateX(-50%);
          height: auto;
        }
        &-offer {
          @mixin m-more-btn-large #fff,var(--color-primary),#fff;
          /* @mixin m-absolute auto,47px,82px,auto; */
          display: block;
          margin-left: auto;
          width: 450px;
          font-size: 1.6rem;
          background: var(--color-primary);
          border-color: var(--color-primary);
        }
      }
    }

    &-navi {
      background-color: transparent;
      margin-top: 0;
      bottom: 0;
      left: 0;
      position: static;
      height: 60px;
      transition: transform var(--base-animespeed-slow);

      &-pccover {
        .is-hide &, .is-shown & {
          width: 1040px;
          margin: 0 auto;
          position: relative;
          height: 80px;
        }

        .is-pagenavi & {
          width: 1040px;
          margin: 0 auto;
          position: relative;
          height: 80px;

        }
      }

      &.is-pagenavi  {
        background-color: var(--bgcolor-secondary);
        height: 80px;
        position: absolute;
        top: 0;
        left: 0;

        .u-pankuzu,
        .u-breadcrumb {
          color: var(--text-primary);
          margin-top: 10px;

          a {
            color: var(--text-primary);

          }
        }
      }

      &.is-hide {
        position: fixed;
        bottom: auto;
        top: 0;
        left: 0;
        margin-top: -80px;
        background-color: var(--bgcolor-secondary);
        height: 80px;
      }

      &.is-shown {
        position: fixed;
        top: 0;
        left: 0;
        bottom: auto;
        margin-top: -80px;
        transform: translateY(80px);
        background-color: var(--bgcolor-secondary);
        height: 80px;

        .u-pankuzu,
        .u-breadcrumb {
          color: var(--text-primary);

          a {
            color: var(--text-primary);

          }
        }

      }

      &-inner {
        @mixin m-flexset;
        position: absolute;
        padding: 0 20px;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60px;

      }

      .u-pankuzu,
      .u-breadcrumb {
        padding: 0;
        position: absolute;
        top: 0;
        left: 20px;
        color: var(--button-text-hover);
        margin-top: 10px;

        a {
          color: var(--button-text-hover);
        }
      }

      &-title {
        padding: 0;
        position: static;
        margin-left: 0;
        @mixin m-flexbase 233px;

        .is-open & {
          margin-top: 0;

          a {
            align-items: center;
            display: flex;
            position: static;
            height: auto;

            &::before {
              display: none;
            }
          }
        }

        a {
          height: 60px;
          transition: all var(--base-animespeed);
          display: flex;
          align-items: center;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 2px;
            width: 0;
            background-color: var(--color-primary);
            transition: width var(--base-animespeed);
          }

          &:hover {

            opacity: .7;

            &::after {
              width: 100%;
            }
          }
        }

        &::before {
          display: none;
        }

        &::after {
          display: none;
        }

        svg {
          fill: var(--button-text-hover);

          .is-hide &, .is-shown & ,.is-pagenavi & {
            fill:  var(--text-primary);
          }
        }
      }

      &-list {
        border-top: none;
        padding: 0;
        display: flex !important;
        @mixin m-flexset;
        flex: 1;

        li {
          border-bottom: none;
          border-left: 1px solid var(--table-head-bordercolor);
          flex: 1;
          line-height: 1;

          .is-hide &, .is-shown & ,.is-pagenavi &{
            border-left: 1px solid transparent;
          }

          &:first-child {
            border-top: none;
          }

          a {
            height: 60px;
            padding: 20px;
            color: var(--button-text-hover);
            text-align: center;
            transition: all var(--base-animespeed);
            position: relative;

            &::after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              height: 2px;
              width: 0;
              background-color: var(--color-primary);
              transition: width var(--base-animespeed);
            }

            .is-hide &, .is-shown & ,.is-pagenavi &{
              color: var(--text-primary);
            }

            &:hover, &.is-active{
              &::after {
                width: 100%;
              }
            }

            &:hover {
              color: var(--color-primary);
            }

            &::before {
              display: none;
            }
          }
        }
      }
    }

    &-container {
      &-flex-order {
        @mixin m-flexset center,wrap;
        .u-product-container-alpha-leadbox {
          order: 2;
          /* padding-top: 100px; */
        }
      }
      &-alpha {
        padding: 0;
        margin-top: 120px;
        @mixin m-inner;

        &-leadbox {
          margin: 0;
          padding: 120px 0 0;
          text-align: center;
        }
      }

      &-why {
        /* margin-top: 80px; */
        margin-top: 130px;
      }
    }
    &-link {
      &-full {
        text-align: center;
        order: 1;
        &-button {
          @mixin m-more-btn-large;
          width: 745px;

          br {
            display: none;
          }
        }
      }
    }
    &-catch {
      font-size: 2.6rem;
      line-height: 1.384615385;
    }

    &-lead {
      font-size: 1.8rem;
      margin-top: 40px;
      line-height: 1.555555556;
    }

    &-feature {
      @mixin m-flexset space-between, wrap;
      width: 820px;
      margin: 60px auto;
      padding: 0;

      dl {
        display: block;
        margin-top: 0;
        @mixin m-flexbase 165px;
      }

      dt {
        text-align: center;

        img {
          width: 165px;
          height: auto;
        }
      }

      dd {
        text-align: center;
        margin-top: 20px;
        font-size: 1.6rem;
      }
    }

    &-variation {
      margin-top: 100px;
      .u-product-noticebox {
        text-align:center;
      }

      &-navi {
        border-bottom: 1px solid var(--text-primary);

        &-cover {
          overflow-x: hidden;
          transform: translate(0, 1px);
        }

        ul {
          @mixin m-flexset center;
          line-height: 0;

          li {
            font-size: 1.4rem;
            margin: 0 30px;

            a {
              color: var(--text-primary);
              padding: 15px 20px 5px;

              &:hover {
                &::before {
                  width: 100%;
                }
              }

              &.is-active {
                color: var(--color-primary);

                &::before {
                  width: 100%;
                }
              }
            }
          }
        }
      }

      &-contents {
        padding: 50px 0;
      }

      &-name {
        text-align: center;
        font-size: 3.2rem;

        br {
          display: none;
        }
        &-limit {
          font-size: 2rem;
          margin-bottom: 5px;
        }
        &-jp {
          font-size: 1.6rem;
        }
      }

      &-color {
        margin: 20px auto 5px;
        font-size: 1.2rem;
        @mixin m-flexset center,nowrap;
        align-items: center;
        dl {
          margin: 0 .5em;
        }
        &-name {
          span {
            width: 14px;
            height: 14px;
            margin: 0 5px;
          }
        }
      }
      &-catch {
        text-align: center;
        margin-top: 0;
        font-size: 1.8rem;
      }

      &-feature {
        margin-top: 30px;
        align-items: center;
        border: none;
        text-align: center;
        display: inline-block;
        width: auto;

        tbody {
          display: inline-block;
          @mixin m-flexset center;

          tr {
            display: inline-block;
            @mixin m-flexset center;
          }

          td {
            display: inline-block;
            border: none;
            font-size: 1.2rem;
            padding: 0 20px;
            text-align: center;
            border-left: 1px solid var(--line-primary);
            color: #404040;
            height: 35px;
            vertical-align: middle;
            display: flex;
            align-items: center;

            &.pc-noborder {
              border: none;
            }
          }

          tr td:nth-child(2) {
            border-left: 1px solid var(--line-primary);
            padding-left: 20px;
          }
        }

        li {
          display: inline-block;
          list-style: none;
          font-size: 1.2rem;
          border-left: 1px solid var(--line-primary);
          padding: 0 2em;

          &::before {
            display: none;
          }

          &:first-child {
            border-left: none;
          }
        }
      }

      &-feature02 {
        margin-top: 35px;

        &-list {
          @mixin m-flexset center;

          dl {
            margin: 0 25px;
          }

          dt {
            font-size: 1.2rem;
          }

          dd {
            font-size: 2.4rem;

            span {
              font-size: 1.6rem;
            }
          }

        }

        &-note {

          &pc {
            float: right;
            font-size: 1.2rem;
            margin-right: 120px;
            margin-top: -1.7em;
          }
        }
      }

      &-size {
        font-size: 1.2rem;
        margin-top: 2em;
        text-align: center;
      }

      &-slick {
        width: 760px;
        margin-top: 30px;

        .u-product-slide-arrow {
          top: 0;
          height: 100%;
          margin-top: 0;
          width: 125px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          flex-direction: column;
          transition: all var(--base-animespeed);

          span {
            display: inline-block;
            font-size: 1.2rem;
          }

          svg {
            width: 16px;
            height: 29px;
            margin: 10px 0;
          }

          &:hover {
            opacity: .6;
          }
        }

        .u-product-prev-arrow {
          left: -125px;
        }
        .u-product-next-arrow {
          right: -125px;
        }

        &-content {
          &-image {
            ul {
              height: 400px;
            }

            li {
              position: absolute;
              top: 0;
              left: 0;
              transition: all var(--base-animespeed);
            }

            img {
              width: 100%;
            }
          }

          &-colors {
            margin-top: 0;

            li {
              width: 18px;
              height: 18px;
              border: 2px solid #fff;
              margin: 0 8px;
              cursor: pointer;

              br {
                display: none;
              }
            }
          }

          &-colortext {
            font-size: 1.4rem;
            margin-top: 12px;

            small {
              font-size: 1.2rem;
              margin-top: 0;
            }
          }
        }
      }

      &-colorarea {
        min-height: 90px;
        margin-top: 20px;
      }

      &-buttons {
        margin-top: 40px;
        @mixin m-flexset center;

        &-button {
          @mixin m-more-btn-large;
          width: 100%;
          width: 337px;
          margin: 0 20px;
        }
        &-caution {
          font-size: 1.2rem;
          margin-top: 10px;
          text-align: center;
        }
      }
    }

    /* why */
    &-why {
      &-header {
        @mixin m-inner;
        padding: 80px 20px 30px;

        &-title {
          font-size: 4.2rem;
        }

        &-lead {
          margin-top: 2.2em;
          font-size: 1.8rem;
          text-align: center;
        }
      }

      &-links {
        min-width: 1300px;
        margin: 20px auto 0;

        a {
          @mixin m-flexset;
        }
      }

      &-text {
        padding: 0;
        @mixin m-flexbase 50%;
        @mixin m-flexset;
        align-items: center;
        background-size: cover;
        background-position: right top;
        &-alpha {
          display: block;
          padding: 0;
          height: auto;
          background-image: none;
        }

        &-beta {
          display: block;
          padding: 0;
          height: auto;
          background-image: none;
        }

        &-inner {
          width: 487px;
          margin-left: auto;
          padding: 0 40px 0 0;
        }

        &-category {
          font-size: 2rem;
          color: var(--color-primary);

          &2 {
            font-size: 2rem;
          }
        }

        &-catch {
          font-size: 2.6rem;
          line-height: 1.333333333;
        }

        &-description {
          font-size: 1.8rem;
          margin-top: 2em;
          font-family: var(--font-tsukushi);

          &2 {
            font-size: 1.8rem;
            margin-top: .5em;
          }
        }
        &-detail {
          font-size: 1.4rem;
          line-height: 1.3;
          margin: 30px 0 0;
          &-price {
            font-size: 2rem;
            &:after {
              font-size: 1.4rem;
            }
          }
        }
        &-button {
          margin-top: 40px;
          @mixin m-more-btn-small var(--button-text-hover), var(--color-primary), var(--button-text-hover);
          width: 120px;
          font-size: 1.2rem;

          span {
            font-family: var(--font-apex-book);
            letter-spacing: .1em;
          }

          i {
            right: 5%;
          }

          &2 {
            margin-top: 20px;
            @mixin m-more-btn-small var(--button-text-hover), var(--color-primary), var(--button-text-hover);
            text-align: left;
            vertical-align: middle;
            width: 140px;
            font-size: 1.2rem;

            span {
              font-family: var(--font-apex-book);
              letter-spacing: .1em;
            }
          }

          &-dl {
            width: 230px;
          }
        }

        &-name {
          font-size: 2.4rem;
          margin-top: .3em;
        }
      }

      &-add, &-others {
        margin-top: 60px;

        .u-product-why-text-alpha {
          height: auto;
          padding: 0;
        }
      }

      &-others {
        .u-product-why-text-beta {
          padding: 0;
        }
      }

      &-add {
        .u-product-why-text-inner {
          padding-top: 0;
        }
      }

      &-image {
        display: block;
        position: relative;

        &-caution {
          color: #fff;
          font-size: 1.4rem;
          @mixin m-absolute auto,1em,1.5em,auto;
          z-index: 2;
        }

        img {
          width: 100%;
          position: relative;
          z-index: 1;
        }

        @mixin m-flexbase 50%;
        line-height: 0;
      }
    }

    /* ムービー */
    &-movies {
      @mixin m-inner;
      padding: 80px 20px;
      margin-top: 80px;

      &-title {
        font-size: 4.2rem;
      }

      &-article {
        margin: 0;
      }

      .u-movies-article {
        @mixin m-flexset flex-start, wrap;
      }

      .u-movies-content {
        @mixin m-flexbase 320px;
        margin-left: 20px;
        margin-bottom: 60px;
        width: 100%;
        padding: 0;

        &:nth-child(3n-2) {
          margin-left: 0;
        }
      }

      .u-movies-content-textbox {
        margin: -20px 0 0 auto;
        padding: 20px 0 0 20px;
        width: 278px;
      }

      .u-movies-content-btncover {
        display: block;
      }

      &-morebutton {
        &-cover {
          margin: 60px auto;
          text-align: center;
        }

        &-button {
          @mixin m-more-btn-large;
          width: 267px;
        }
      }
    }

    /* gallery */
    &-gallery {
      @mixin m-inner;
      padding: 40px 20px;

      &-title {
        font-size: 4.2rem;
      }

      &-cover {
        display: block;
        margin-top: 30px;
      }

      &slick {
        display: none;
      }

      &-item {
        width: 490px;
        margin-bottom: 20px;
        line-height: 0;
        padding: 0;

        a {
          display: block;
          position: relative;
          overflow: hidden;

          &:hover {
            img {
              transform: scale(1.1, 1.1);
            }
          }
        }

        img {
          transition: transform var(--base-animespeed) var(--animate-easeinout);
          width: 100%;
          height: auto;
        }

        &-s {
          width: 235px;
        }
      }
    }

    &-campaign {
      padding: 120px 0 40px;
      &-banner {
        margin: 0 auto;
        @mixin m-flexset space-between,nowrap;
        > * {
          @mixin m-flexbase 50%;
        }
        &-detail {
          padding: 0 40px 0 60px;
          @mixin m-flexset center,nowrap;
          flex-direction: column;
          &-title {
            font-size: 3.2rem;
            margin-top: auto;
            span {
              font-size: 2.6rem;
            }
            small {
              font-size: 2rem;
            }
          }
          &-term {
            font-size: 2.6rem;
            margin: 5px 0 0;
          }
          &-description {
            margin: 1em 0 auto;
          }
          &-button {
            @mixin m-more-btn-small var(--color-primary),#fff,var(--color-primary);
            font-size: 1.2rem;
            width: 120px;
            margin-bottom: auto;
          }
        }
      }
    }

    &-support {
      /* @mixin m-inner;
      padding: 120px 20px 40px; */
      padding-bottom: 40px;

      .u-default-container {
        padding: 0 20px;
      }

      &-title {
        font-size: 4.2rem;
        padding-top: 120px;
      }

      &-links {
        margin-top: 20px;
        a {
          @mixin m-flexset;
        }
      }

      &-text {
        padding: 0 40px 0 60px;
        @mixin m-flexbase 50%;
        @mixin m-flexset;
        align-items: center;

        &-title {
          font-size: 2.6rem;
        }

        &-description {
          margin-top: 1em;
        }

        &-button {
          margin-top: 30px;
          @mixin m-more-btn-small;
          width: calc(130/375*100)vw;
          width: 140px;
          font-size: 1.2rem;
        }
      }

      &-image {
        @mixin m-flexbase 50%;
        line-height: 0;
      }

      .u-product-variation-buttons {
        margin-top: 80px;
        padding: 0;
      }
    }

    /* otherlineup */
    &-otherlineup {
      @mixin m-inner;
      padding: 120px 20px 160px;
      &-title {
        font-size: 4.2rem;
      }

      &-lineup {
        @mixin m-flexset center;
        margin-top: 40px;
        width: auto;

        .site-pageheader-spnavi-models-model {
          margin: 0 20px;
        }

        &.allmodel {
          .site-pageheader-spnavi-models-model {
            margin: 0;
          }
        }

        .slick-slide {
          &:first-of-type .site-pageheader-spnavi-models-model {
            margin-left: 0;
          }
          &:last-of-type .site-pageheader-spnavi-models-model {
            margin-right: 0;
          }
        }

        .u-product-slide-arrow{
          margin-top: 0;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          cursor: pointer;
          transition: all var(--base-animespeed);

          &:hover {
            background-color: rgba(var(--bgcolor-ovarlay-light));
          }
          svg {
            width: 16px;
            height: 29px;
          }
        }
        .u-product-prev-arrow {
          position: absolute;
          top: 0;
          left: -70px;
        }
        .u-product-next-arrow {
          position: absolute;
          top: 0;
          right: -70px;
        }
      }
      
	  &-large{
		  max-width: 1186px;
		  .u-product-otherlineup-lineup{
			  flex-wrap: wrap;
			  .site-pageheader-spnavi-models-model-image img {
				    width: 80%;
				    display: inline-block;
				}
				.site-pageheader-spnavi-models-model a {
				    display: block;
				    width: 225px;
				    padding: 30px 0;
				    position: relative;
				}
		  }
	  }
      .u-product-spec-caution {
        /* @mixin m-inner; */
        margin-top: 8em;
      }
    }

    /* mondoalfa */
    &-mondoalfa {
      padding: 0 0 80px;

      &-title {
        img {
          width: 240px;
        }

        &-cover {
          text-align: center;
          padding: 50px 0 20px;
          border-bottom: 2px solid var(--color-primary);
        }
      }

      &-lead {
        margin-top: 1em;
      }

      &-morebutton {
        margin: 60px 0 0;
        text-align: center;

        &-button {
          @mixin m-more-btn-large;
          width: 267px;
        }
      }

      &-news {
        margin-top: 0;
        padding: 0;

        &-article {
          @mixin m-flexset flex-start;
          margin: -40px auto 0;
          width: 1000px;
        }

        .u-news-article {
          margin: 0 auto;
          width: 1000px;
        }

        .u-news-content-textbox {
          background-color: var(--bgcolor-secondary);
        }

        .u-news-content-btn {
          @mixin m-more-btn-small;
          margin-top: 20px;
          width: 150px;
          font-size: 1.2rem;

          span {
            font-family: var(--font-apex-book);
            letter-spacing: .1em;
          }
        }
      }
    }

    &-pagekv {
      margin-top: 0;
      padding-top: 80px;

      &-kv {
        height: 420px;
        padding: 30px 0;

      }

      &-inner {
        @mixin m-inner;
      }

      &-title {
        font-size: 2rem;

      }

      &-catch {
        font-size: 3.2rem;
      }
    }

    &-cover {
      @mixin m-inner;
      margin: 60px auto;

      .u-unckernavi {


        ul {
          @mixin m-flexset flex-start;
          overflow: hidden;
        }

        li {
          margin-right: 50px;
        }

        a {
          padding: 0 0 15px;
        }
      }
    }

    &-section {
      padding: 100px 0;

      &:first-child {
        padding-top: 70px;
      }

      &-sectiontitle {
        font-size: 3.2rem;
      }

      &-lead {
        margin-top: 1em;
        font-family: var(--font-tsukushi);
        font-feature-settings: "palt"1;
        line-height: 1.5625;
        font-size: 1.8rem;
        &-title {font-size: 2.4rem;}
        &-notice {
          font-size: 1.4rem;
        }
      }

      &-heading {
        font-size: 2.6rem;
      }
      &-note {
        font-size: 1.4rem;
      }
    }

    &-slick {
      &-primary {
        margin: 40px 0 0;
        position: static;

        &-content {
          width: 100%;
          padding: 0;
        }

        .u-product-prev-arrow {
          display: none !important;
        }

        .u-product-next-arrow {
          display: none !important;
        }

        &-imagebox {
          position: relative;

          img {
            width: 100%;
          }

          span {
            bottom: 20px;
            right: 20px;
            font-size: 1.2rem;
          }
        }

        &-text {
          text-align: center;
        }

        &-thumbnail {
          margin-top: 40px;
          width: 780px;
          margin: 40px auto 0;
          position: relative;
          display: block;

          .u-product-slide-arrow {
            margin-top: 0;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            cursor: pointer;
            transition: all var(--base-animespeed);

            &:hover {
              background-color: rgba(var(--bgcolor-ovarlay-light));
            }

            svg {
              width: 16px;
              height: 29px;
            }
          }

          .u-product-prev-arrow {
            position: absolute;
            top: 0;
            left: -70px;
          }
          .u-product-next-arrow {
            position: absolute;
            top: 0;
            right: -70px;
          }

          &-list {
            list-style: none;
            @mixin m-flexset;

            li {
              cursor: pointer;
              transition: opacity var(--base-animespeed);
              line-height: 0;

              &:hover ,&.thumbnail-current {
                opacity: .5;
              }
            }

            img {
              width: 148px;
              height: auto;
            }
          }
        }
      }
      &-secondary {
        margin: 20px 0 0;
        position: static;
        @mixin m-flexset flex-start, wrap;

        &.for-sp {
          display: none;
        }


        &-content {
          @mixin m-flexbase 320px;
          padding: 0;
          margin: 0 20px 50px 0;

          &:nth-child(3n){
            margin-right: 0
          }
        }

        &-imagebox {
          position: relative;

          img {
            width: 100%;
          }


        }
        &-title {
          margin-top: 1em;
          font-weight: bold;
        }

        &-text {
          margin-top: 1em;
        }
      }
      &-tertiary {
        @mixin m-flexset space-between,nowrap;
        a:hover {
          text-decoration: none;
        }
        &-list {
          @mixin m-flexbase 320px;
          a {
            @mixin m-flexset space-between,wrap;
            flex-direction: column;
            height: 100%;
          }
          &-detail {
            width: 100%;
            figure {
              position: relative;
              height: 208px;
              &:before {
                content: '';
                width: 0;
                height: 100%;
                position: absolute;
                top:0;
                left:0;
                background-color: var(--color-hoverbg);
                transition: width var(--base-animespeed) var(--animate-easeinout);
                z-index: 2;
                mix-blend-mode: multiply;

                a:hover & {
                  width: 100%;
                }
              }

              img {
                width: 100%;
                transition: opacity var(--base-animespeed);
                z-index: 1;
                position: absolute;
                mix-blend-mode: multiply;

              }

              &:after {
                background-size: 51px;
                width: 51px;
                height: 51px;
                margin: -25px 0 0 -25px;
                position: absolute;
                z-index: 10;

              }
            }
            &-text {
              background-color: var(--bgcolor-primary);
              margin: -20px 0 0 auto;
              padding: 20px 0 0 20px;
              width: 278px;
              position: relative;
              z-index: 9;
              p {
                margin-top: 5px;
                font-size: 1.8rem;
              }
            }
            &-tag {
              font-size: .9rem;
              margin: 5px 0 10px;
              li {
                border-radius: 3px;
                padding: 5px 10px 1px;
              }
            }
          }
        }
        &-btncover {
          text-align: left;
          margin: 15px 0 0 60px;

          &-btn {
            @mixin m-more-btn-small var(--color-primary), var(--button-text-hover), var(--color-primary);
            font-size: 1.2rem;
            span {
              font-family: var(--font-apex-book);
              letter-spacing: .1em;
            }
          }
        }
      }
    }

    &-performance {
      margin: 20px 0 0;
      @mixin m-flexset flex-start, wrap;

      a {
        color: var(--text-primary);

        &:hover {
          color: var(--color-primary);
        }
      }

      &-content {
        @mixin m-flexbase 320px;
        padding: 0;
        margin: 0 20px 50px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &:hover {
          text-decoration: none;

          .u-product-performance-imagebox::before {
            width: 100%;
          }
        }


        &:nth-child(3n){
          margin-right: 0
        }
      }

      &-imagebox {
        position: relative;
        width: 320px;
        height: 140px;
        line-height: 0;

        &::before {
          content: '';
          height: 140px;
          width: 0;
          background-color: var(--color-hoverbg);
          transition: width var(--base-animespeed) var(--animate-easeinout);
          position: absolute;

        }


        img {
          position: absolute;
          top: 0;
          left: 0;
          transition: all var(--base-animespeed);
          mix-blend-mode: multiply;
          width: 100%;
        }


      }
      &-title {
        margin-top: 1em;
        font-weight: bold;

        flex: 1;
      }


      &-button {
        @mixin m-more-btn-small;
        width: 130px;
        font-size: 1.2rem;
        font-family: var(--font-apex-book);
        letter-spacing: .1em;
        margin-top: .7em;;
      }

      &-modaal {

        &-image {
          margin: -30px -30px 0;
          position: relative;
          span {
            color: var(--button-text-hover);
            font-family: var(--font-apex-book);
            position: absolute;
            bottom: 20px;
            right: 20px;
            font-size: 1.2rem;
            text-shadow: 1px 1px 1px #000;
          }
          img {
            width: 100%;
          }
        }

        &-textbox {
          padding: 40px 30px 10px;
          line-height: 1.375;
        }

        &-title {
          font-weight: bold;
        }

        &-text {
          margin: 20px 0 30px;
        }
      }

    }

    &-moviebox {
      margin: 100px 0 0;


      &-link {
        width: 900px;
        margin: 0 auto;
        @mixin m-flexset;
        align-items: center;
        /* align-items: flex-end; */
      }

      &-imagebox {
        position: relative;
        @mixin m-flexbase 358px;
        margin-right: 50px;
        line-height: 0;

        img {
          width: 100%;
        }

        &::after {
          content: '';
          background: url(../image/icon-play.svg) center no-repeat;
          background-size: 51px;
          width: 51px;
          height: 51px;
          position: absolute;
          top: 50%;
          left: 50%;
          margin: -25.5px 0 0 -25.5px;
        }
      }

      &-textbox {
        padding: 0;
        flex: 1;
      }

      &-title {
        font-size: 2.6rem;

        &::before {
          width: 60px;
        }

      }

      &-kana {
        font-size: 1.2rem;
      }

      &-position {
        font-size: 1.2rem;
      }
    }


    &-widthpicture {
      margin: 20px 0 0;

      img {
        width: 100%;
        height: auto;
      }
    }

    &-picturelist {
      @mixin m-flexset flex-start , wrap;

      li {
        line-height: 0;
        margin: 20px 20px 0 0;
        @mixin m-flexbase 320px;

        &:nth-child(3n){
          margin-right: 0;
        }
      }

      img {
        width: 100%;
      }
    }

    &-safemovie {
      margin: 20px 0 0;

      &-link {
        display: block;
      }

      > * {
        @mixin m-flexset;
      }
      &-imagebox {
        line-height: 0;
        background-color: #000;
        @mixin m-flexbase 50%;
        display: flex;
        align-items: center;
        .u-product-safemovie-link & {

          &::after {
            background-size: 51px;
            width: 51px;
            height: 51px;
            margin: -25.5px 0 0 -25.5px;
          }
        }

        picture {
          display: block;
        }

        img {
          width: 100%;

        }

      }

      &-textbox {
        padding: 35px 60px;
        @mixin m-flexbase 50%;
        @mixin m-flexset;
        flex-direction: column;
        align-items: stretch;

        .u-product-safemovie-nolink & {
          justify-content: center;
        }
      }

      &-title {
        font-size: 2.6rem;
      }

      &-text {
        margin-top: 30px;
      }

      &-button {
        @mixin m-more-btn-small;

        width: 140px;
        font-size: 1.2rem;
        &-wide {
          @mixin m-more-btn-small;
          width: 180px;
          font-size: 1.2rem;
          &:hover {
            text-align: center;

            span {
              color: #fff;
            }
            svg {
              fill: #fff;
            }
            i {
              background-color: #fff;
              right: -5px;

              &:before {
                background-color: #fff;
              }
            }
            &:before {
              width: 100%;
            }
          }
        }
      }
    }

    &-noticebox {
      font-size: 1.4rem;
      a {
        color: var(--color-primary);
        &:hover {
          text-decoration: underline;
        }
      }
      &.u-product-global-caption {
        margin-top: 70px;
      }
    }
    &-twoconts {
      ul { margin: 0 30px 0 0;}
    }

    &-threeconts {

      @mixin m-flexset;

      &-image {
        margin-top: 20px;
        @mixin m-flexbase 320px;

      }

      &-text {
        font-weight: bold;
        margin-top: .5em;
      }
    }

    &-spec {

      &-table {
        margin-top: 40px;
        font-size: 1.2rem;
        overflow: hidden !important;
        thead {
          th {
            padding: 20px 15px 10px 0;
            white-space: nowrap;
            &:first-child {
              position: unset;
            }
          }
        }

        tbody {
          th {
            padding: 12px 8px 12px 0;
            position: unset;
          }

          td {
            padding: 12px 8px 12px 0;
          }
        }

        &-specifications {
          thead {
            th:first-child {
              width: 230px;
            }

            th {
              width: 192px;

            }
          }

          tbody {

            td {
              padding: 12px 30px 12px 0;
            }
          }
        }

        &-colors {

          &-title {
            @mixin m-flexbase 200px;


          }
        }

        &-colors1 {
          margin: 40px 0 0;
          padding: 0;
          @mixin m-flexset;
          align-items: center;

          table {
            border-top: 1px solid var(--table-body-bordercolor);
            margin-top: 0;
            @mixin m-flexbase 800px;
            min-width: inherit;
          }

          tbody tr:first-child th:first-child {
            width: 255px;
            position: static;
          }
          th {
            border-right: none;
            position: static !important;
          }
          th + td {
            padding-left: 0;
          }
          tbody td {
            width: 272px;
            text-align: center;
          }

          img {
            width: 107px;
          }
          tbody tr:last-child {
            th, td {
              border-bottom: none;
            }
          }
        }
        &-colors2 {
          margin: 0;
          padding: 0;
          border-top: 2px solid var(--table-head-bordercolor);
          @mixin m-flexset;
          align-items: center;

          table {
            border-top: none;
            margin-top: 0;
            @mixin m-flexbase 800px;
            min-width: inherit;
          }

          tbody tr:first-child th:first-child {
            width: auto;
          }
          tbody th {
              border-right: none;
            text-align: center;
            width: 150px;
            padding-right: 105px;
            position: static !important;
          }
          table tbody td {
            text-align: center;
          }
          th + td {
            padding-left: 0;
          }
          td {
            width: 272px;
          }

          img {
            width: 150px;
          }
        }
        &-colors3 {
          border-top: 2px solid var(--table-head-bordercolor);
          border-bottom: 2px solid var(--table-head-bordercolor);
          margin: 80px 0 40px;

          .pc-hidetr {
            display: none;
          }


          tbody th.u-product-spec-table-colors3-tabletitle {
            font-weight: bold;
          }

          .bordertr {
            border-top: 2px solid var(--table-head-bordercolor);
          }

          tr {
            th {
              width: 200px;
              padding: 5px 0;
              border-right: none;

              img {
                width: 140px;
              }

              &.pc-categoryth {
                border: none;
                font-weight: bold;
                padding-left: 0;
                text-align: left;
                width: 200px;
              }
            }

            &.sp-nobr {
              td {
                white-space: nowrap;
                padding: 15px 15px;
              }
            }

            &.bodycolor {
              th {
                text-align: center;
                border-right: none;
              }
              td {
                border-left: 1px solid var(--table-body-bordercolor);
                padding: 15px 10px;
                white-space: nowrap;
              }
            }

            td {
              text-align: center;

              &.bordertd {
                border-left: 1px solid var(--table-body-bordercolor);

                &-dark {
                  border-left: 1px solid var(--table-head-bordercolor);
                }
              }

              img {
                width: 109px;
              }
            }
          }
        }
      }
      &-limited {
        margin-top: 50px;
        &-model {
          font-size: 1.2rem;
          margin-bottom: 10px;
          li {
            width: 545px;
            &:first-child {
              width: 455px;
            }
          }
        }
        &-interior {
          margin: 0;
          padding: 0;
          @mixin m-flexset;
          align-items: center;

          table {
            border-top: 1px solid var(--table-body-bordercolor);
            margin-top: 0;
            @mixin m-flexbase 800px;
            min-width: inherit;
            tbody tr:first-child th:first-child {
              width: 255px;
              position: static;
            }
            th {
              border-right: none;
              position: static !important;
            }
            th + td {
              padding-left: 0;
            }
            tbody td {
              width: 272px;
              text-align: center;
            }
          }

          img {
            width: 107px;
          }
        }
        &-bodycolor {
          margin: 0;
          padding: 0;
          @mixin m-flexset;
          align-items: center;

          table {
            border-top: none;
            margin-top: 0;
            @mixin m-flexbase 800px;
            min-width: inherit;
            tbody th {
              border-right: none;
              text-align: center;
              width: 260px;
              padding-right: 100px;
              position: static !important;
            }
            tbody td {
              text-align: center;
            }
            th + td {
              padding-left: 0;
            }
            td {
              width: 272px;
            }
          }
          img {
            width: 150px;
          }
        }
      }
      &-caution {
        margin-top: 10em;
        a {
          color: var(--color-primary);
          &:hover {
            text-decoration:underline;
          }
        }
      }
    }

    &-pricelist {
      width: 100%;
      margin: 0 auto;
      @mixin m-flexset flex-start;

      &-content {
        width: 250px;
        margin: 30px 20px 0 0;
      }

      &-image {
        img {
          width: 100%;
        }
      }
      &-limit {
        font-size: 1.2rem;
        margin-bottom: 8px;
      }
      &-name {
        margin-bottom: 10px;
      }
      &-pricekibo {
        font-size: 1.2rem;
      }
      &-price {
        font-size: 1.4rem;

        span {
          font-size: 1.6rem;
        }
      }
      &-pricezei {
        font-size: 1.2rem;
      }
      &-recyclea {
        font-size: 1.2rem;
        margin-top: 1em;
      }
      &-recycleb {
        font-size: 1.4rem;

        span {
          font-size: 1.6rem;
          font-family: var(--font-apex-book);
        }
      }
    }
  }

  *::-ms-backdrop,
    .site-pageheader-spnavi-models-model-kana,.u-pankuzu li,.u-breadcrumb li {
      line-height: 2;
    }
    _:-ms-lang(x)::-ms-backdrop,
    .u-news-content-imagebox::before,.index-models-model-image::before,.u-movies-content-imagebox::before,.u-product-slick-tertiary-list-detail figure:before {
      opacity: .7;
    }
    *::-ms-backdrop,
    .u-product-campaign-banner-detail-title {
      margin-top: 0;
    }
    *::-ms-backdrop,
    .u-product-campaign-banner-detail-description {
      margin: 1em 0;
    }
}

/* アニメーション設定 ================================================ */
@keyframes textanime {
  0% {
    left: 0%;
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  80% {
    left: 47%;
    opacity: 1;
  }
  100% {
    left: 47%;
    opacity: 0;
  }
}
@keyframes llineanime {
  0% {
    left: -60%;
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  80% {
    left: -13%;
    opacity: 1;
  }
  100% {
    left: -13%;
    opacity: 0;
  }
 }

@keyframes rlineanime {
  0% {
    left: 60%;
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  80% {
    left: 107%;
    opacity: 1;
  }
  100% {
    left: 107%;
    opacity: 0;
  }
}
@keyframes searchicontoleft {
  0% {
    right: 20px;
    opacity: 1;
  }
  30% {
    opacity: 0;
  }

  80% {
    right: 980px;
    opacity: 0;
  }
  100% {
    right: 980px;
    opacity: 1;
  }
}
@keyframes searchicontoright {
  0% {
    right: 980px;
    opacity: 1;
  }
  30% {
    opacity: 0;
  }

  80% {
    right: 20px;
    opacity: 0;
  }
  100% {
    right: 20px;
    opacity: 1;
  }
}
@keyframes searchboxhide {
  0% {
    top: 0;
    opacity: 1;
  }
  99% {
    opacity: 0;
    top: 0;
  }
  100% {
    opacity: 0;
    top: -80px;
  }
}