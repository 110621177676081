@font-face {
	font-family: 'sequel';
	src: url(/common/fonts/Sequel-Regular.ttf);
}

html {
  &.is-fixed {
    overflow: hidden;
  }
}

body {
  background-color: var(--bgcolor-primary);
  color: var(--text-primary);
  font-size: calc(16/375*100)vw;
  font-family: var(--base-font);
  line-height: 1;
  -webkit-text-size-adjust: 100%;

  &.is-fixed {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}

a {
  color: var(--linkcolor);
  text-decoration: none;
  outline: 0;
}

.for-pc {
  display: none;
}

.site {

  /* 共通ヘッダー ================================================ */
  &-pageheader {
    background-color: var(--header-bgcolor);
    border-bottom: calc(1/375*100)vw solid var(--header-bordercolor);
    height: calc(64/375*100)vw;
    width: 100%;
    animation-duration: .8s;
    animation-delay: 3s;
    animation-fill-mode: forwards;
    position: relative;
    transition: backgrond-color var(--base-animespeed);
    z-index: 9999;

    .is-darkheader & {
      background-color: var(--header-bgcolor-secondary);
      border-bottom: none;
    }

    .is-fixed & {
      background-color: var(--header-bgcolor);
      border-bottom: 1px solid var(--line-primary);
    }

    &-inner {
      @mixin m-flexset;
      align-items: center;
    }

    &-logo {
      @mixin m-flexset;
      align-items: center;
      padding: calc(15/375*100)vw calc(20/375*100)vw;
      flex: 1;

      &-mark {
        margin-right: calc(9/375*100)vw;
        line-height: 0;

        img {
          width: calc(35/375*100)vw;
          height: calc(35/375*100)vw;
        }
      }

      &-text {
        flex: 1;

        svg {
          fill: var(--color-primary);
          width: calc(122/375*100)vw;
          height: calc(25/375*100)vw;
          transition: fill var(--base-animespeed);

          .is-darkheader & {
            fill: var(--header-textcolor-secondary);
          }

          .is-fixed & {
            fill: var(--color-primary);
          }
        }
      }
    }

    /* スマホモデルナビ ================================================ */
    &-spnavi {
      &-ul {
        list-style: none;

        &-li {
          &-a {
            position: relative;
            padding: 0 calc(30/375*100)vw 0 calc(10/375*100)vw;
            height: calc(63/375*100)vw;
            @mixin m-flexset;
            align-items: center;
            border-bottom: calc(2/375*100)vw solid transparent;
            color: var(--header-textcolor);

            .is-darkheader & {
              color: var(--header-textcolor-secondary);
            }

            .is-fixed & {
              color: var(--header-textcolor);
            }

            &::before {
              content: '';
              width: calc(30/375*100)vw;
              height: calc(30/375*100)vw;
              position: absolute;
              top: 25%;
              right: 0;
              background: url('../image/icon-arrow-dwon-red-bkdark.svg') center center no-repeat;
              background-size: calc(10.5/375*100)vw calc(5/375*100)vw;
              transition: transform var(--base-animespeed-slow);
            }

            &::after {
              content: '';
              width: 0;
              height: calc(2/375*100)vw;
              background-color: var(--color-primary);
              transition: width var(--base-animespeed-slow);
              position: absolute;
              left: 0;
              bottom: calc(-2/375*100)vw;
            }

            &.is-open

            /*, &:hover*/
              {
              &::before {
                background-image: url('../image/icon-arrow-dwon-red.svg');
                transform: rotate(180deg);
              }

              &::after {
                width: 100%;
              }
            }
          }
        }
      }

      &-models {
        display: none;
        position: absolute;
        width: 100vw;
        top: 100%;
        left: 0;
        background-color: rgba(var(--header-dropdown-bgcolor));
        height: calc(100vh- calc(64/375*100vw));
        overflow: scroll;
        padding: 0 calc(20/375*100)vw calc(64/375*100)vw;
        z-index: 999;

        &-model {
          border-bottom: calc(1/375*100)vw solid var(--line-primary);
          padding: calc(40/375*100)vw 0 calc(80/375*100)vw;
          text-align: center;

          a {
            display: block;
            color: var(--header-textcolor);
          }

          &-category {
            font-size: calc(12/375*100)vw;
          }

          &-image {

            img {
              width: calc(235/375*100)vw;
            }
          }

          &-name {
            font-size: calc(16/375*100)vw;
            font-family: 'sequel';
            .new{
	            color: var(--color-primary)!important;
	            margin-right: .2em;
	            font-family: 'sequel';
            }
          }

          &-new {
            color: var(--color-primary);
            margin-right: .2em;
            font-family: 'sequel';
          }

          &-kana {
            font-size: calc(12/375*100)vw;
          }

          &-price {
            margin-top: calc(8/375*100)vw;
            font-family: 'sequel';

            &-yen {
              font-size: calc(14/375*100)vw;
            }

            &-discon {
              font-size: calc(12/375*100)vw;
            }
          }

          &-btn {
            margin-top: calc(18/375*100)vw;
            width: calc(140/375*100)vw;
            @mixin m-more-btn-small-hover var(--button-text-hover), var(--color-primary);
            font-size: calc(12/375*100)vw;
            /* padding-right: 0; */

            span {
              font-family: 'sequel';
              letter-spacing: .1em;
            }

            i {
              right: 5%;
            }
          }
        }
      }
    }

    &-btn {
      width: calc(63/375*100)vw;
      height: calc(63/375*100)vw;
      position: relative;

      &-bar01 {
        background-color: var(--color-primary);
        height: calc(1/375*100)vw;
        width: calc(28/375*100)vw;
        position: absolute;
        top: 36%;
        left: 10%;

        &.is-open {
          animation-name: ani-navibtn01;
          animation-duration: .6s;
          animation-fill-mode: forwards;
        }

        &.is-close {
          animation-name: ani-navibtn01-out;
          animation-duration: .6s;
          animation-fill-mode: forwards;
        }

        .is-darkheader & {
          background-color: var(--header-textcolor-secondary);
        }

        .is-fixed & {
          background-color: var(--color-primary);
        }
      }

      &-bar02 {
        background-color: var(--color-primary);
        height: calc(1/375*100)vw;
        width: calc(21/375*100)vw;
        position: absolute;
        top: 47%;
        left: 10%;

        &.is-open {
          animation-name: ani-navibtn02;
          animation-duration: .6s;
          animation-fill-mode: forwards;
        }

        &.is-close {
          animation-name: ani-navibtn02-out;
          animation-duration: .6s;
          animation-fill-mode: forwards;
        }

        .is-darkheader & {
          background-color: var(--header-textcolor-secondary);
        }

        .is-fixed & {
          background-color: var(--color-primary);
        }
      }

      &-bar03 {
        background-color: var(--color-primary);
        height: calc(1/375*100)vw;
        width: calc(26/375*100)vw;
        position: absolute;
        top: 58%;
        left: 10%;

        &.is-open {
          animation-name: ani-navibtn03;
          animation-duration: .6s;
          animation-fill-mode: forwards;
        }

        &.is-close {
          animation-name: ani-navibtn03-out;
          animation-duration: .6s;
          animation-fill-mode: forwards;
        }

        .is-darkheader & {
          background-color: var(--header-textcolor-secondary);
        }

        .is-fixed & {
          background-color: var(--color-primary);
        }
      }
    }

    @keyframes ani-navibtn01 {
      50% {
        top: 48%;
        width: calc(28/375*100)vw;
        transform: rotate(0);
      }

      100% {
        top: 48%;
        width: calc(34/375*100)vw;
        transform: rotate(-45deg);
        left: 4%;
      }
    }

    @keyframes ani-navibtn01-out {
      0% {
        top: 48%;
        left: 4%;
        width: calc(34/375*100)vw;
        transform: rotate(-45deg);
      }

      50% {
        top: 48%;
        width: calc(28/375*100)vw;
        transform: rotate(0);
      }

      100% {}
    }

    @keyframes ani-navibtn02 {
      50% {
        top: 48%;
        left: 4%;
        width: calc(21/375*100)vw;
      }

      51% {
        width: 0;
      }

      100% {
        width: 0;
        top: 48%;
        left: 4%;
      }
    }

    @keyframes ani-navibtn02-out {
      0% {
        width: 0;
        top: 48%;
        left: 4%;
      }

      50% {
        width: 0;
      }

      51% {
        top: 48%;
        width: calc(21/375*100)vw;
      }

      100% {}
    }

    @keyframes ani-navibtn03 {
      50% {
        top: 48%;
        width: calc(28/375*100)vw;
        transform: rotate(0);
      }

      100% {
        top: 48%;
        width: calc(34/375*100)vw;
        transform: rotate(45deg);
        left: 4%;
      }
    }

    @keyframes ani-navibtn03-out {
      0% {
        top: 48%;
        width: calc(34/375*100)vw;
        transform: rotate(45deg);
      }

      50% {
        top: 48%;
        width: calc(28/375*100)vw;
        transform: rotate(0);
      }

      100% {}
    }

    /* スマホナビ ================================================ */
    &-mainnavi {
      display: none;
      position: absolute;
      width: 100vw;
      left: 0;
      top: 100%;
      background-color: rgba(var(--header-dropdown-bgcolor));
      height: calc(100vh- calc(64/375*100vw));
      overflow: scroll;
      padding: 0 0 calc(144/375*100)vw;
      z-index: 999;

      &-ul {
        list-style: none;
      }

      &-category {
        background-color: var(--bgcolor-tertiary);
        padding: calc(17.5/375*100)vw calc(35/375*100)vw calc(15.5/375*100)vw calc(20/375*100)vw;
        position: relative;
        cursor: pointer;
        display: block;
        margin-bottom: calc(1/375*100)vw;
        color: var(--header-textcolor);

        &::before {
          content: '';
          width: calc(35/375*100)vw;
          height: calc(35/375*100)vw;
          position: absolute;
          top: 12%;
          right: 3%;
          background: url('../image/icon-arrow-dwon-red.svg') center center no-repeat;
          background-size: calc(10.5/375*100)vw calc(5/375*100)vw;
          transition: transform var(--base-animespeed-slow);
        }

        &.is-open {
          &::before {
            transform: rotate(180deg);
          }
        }
      }
      &-wide {
        line-height: 1.3;
      }
      &-occont {
        padding: 0 calc(20/375*100)vw;
        list-style: none;

        &.is-closed {
          display: none;
        }

        >li {
          border-top: calc(1/375*100)vw solid var(--line-primary);

          &:first-child {
            border-top: none;
          }
        }

        a {
          display: block;
          padding: calc(15/375*100)vw 0 calc(14.5/375*100)vw;
          position: relative;
          color: var(--header-textcolor);

          &::before {
            content: '';
            width: calc(35/375*100)vw;
            height: calc(35/375*100)vw;
            position: absolute;
            top: 12%;
            right: -3%;
            background: url('../image/icon-arrow-dwon-red.svg') center center no-repeat;
            background-size: calc(10.5/375*100)vw calc(5/375*100)vw;
            transform: rotate(-90deg);
          }
        }

        &-description {
          font-size: calc(12/375*100)vw;
          display: block;
          width: 94%;
          margin-top: .3em;
          line-height: 1.4;
        }
      }

      /* その他リンク ================================================ */
      &-links {
        margin-top: calc(25/375*100)vw;
        padding: 0 calc(20/375*100)vw;

        &-ul {
          list-style: none;

          li {
            border-bottom: calc(1/375*100)vw solid var(--line-primary);
          }

          a {
            display: block;
            padding: calc(15/375*100)vw 0 calc(14.5/375*100)vw;
            position: relative;
            color: var(--header-textcolor);

            &::before {
              content: '';
              width: calc(35/375*100)vw;
              height: calc(35/375*100)vw;
              position: absolute;
              top: 20%;
              right: -3%;
              background: url('../image/icon-arrow-dwon-red.svg') center center no-repeat;
              background-size: calc(10.5/375*100)vw calc(5/375*100)vw;
              transform: rotate(-90deg);
            }

            svg {
              fill: var(--header-textcolor);
              width: calc(12.5/375*100)vw;
              height: calc(21.3/375*100)vw;
              margin-right: calc(10/375*100)vw;
              transform: translate(0, 25%);
            }
          }
        }
      }

      /* 検索窓 ================================================ */
      &-searchbox {
        margin-top: calc(25/375*100)vw;
        padding: 0 calc(20/375*100)vw;

        &-inner {
          border-bottom: calc(2/375*100)vw solid var(--header-textcolor);
          position: relative;
        }

        &-inputbox {
          padding: 0;
          border: none;
          border-radius: 0;
          outline: none;
          background: none;
          font-size: calc(16/375*100)vw;
          width: 100%;
          padding: calc(10/375*100)vw 0 calc(10/375*100)vw calc(25/375*100)vw;
        }

        &-searchicon {
          fill: var(--header-textcolor);
          width: calc(19/375*100)vw;
          height: calc(19/375*100)vw;
          position: absolute;
          top: 30%;
          left: 0;
        }

        &-closeicon {
          display: none;
        }
      }
    }
  }

  /* サーチボックス PC ============================= */
  &-searchheadcloser,
  &-searchheader {
    display: none;
  }

  /* 共通フッター ================================================*/
  &-pagefooter {
    &-sns {
      background-color: var(--footer-bgcolor-secondary);
      color: var(--footer-text-secondary);

      &-inner {
        padding: calc(20/375*100)vw;
      }

      &-ul {
        text-align: center;
        padding: 0 calc(50/375*100)vw;

        &-li {
          display: inline-block;
          margin: 0 calc(15/375*100)vw;

          &-a {
            display: block;
            width: calc(24/375*100)vw;
            height: calc(24/375*100)vw;
            text-indent: -9999px;
            background-repeat: no-repeat;
            background-position: center;

            &.facebook {
              background-image: url(../image/icon-facebook@2x.png);
              background-size: calc(24/375*100)vw;
            }

            &.twitter {
              background-image: url(../image/icon-twitter@2x.png);
              background-size: calc(26/375*100)vw calc(21/375*100)vw;
            }

            &.youtube {
              background-image: url(../image/icon-youtube@2x.png);
              background-size: calc(27/375*100)vw calc(20/375*100)vw;
              width: calc(27/375*100)vw;
              margin-top: calc(22/375*100)vw;
            }

            &.instagram {
              background-image: url(../image/icon-instagram@2x.png);
              background-size: calc(24/375*100)vw;
            }

            &.line {
              background-image: url(../image/icon-line@2x.png);
              background-size: calc(24/375*100)vw;
            }
            
            &.mail {
              background-image: url(../image/icon-mail@2x.png);
              background-size: contain;
              width: calc(27/375*100)vw;
              margin-top: calc(22/375*100)vw;
            }
          }
        }
      }
    }

    &-navis {
      background-color: var(--footer-bgcolor-primary);
      color: var(--footer-text-secondary);
      padding: calc(15/375*100)vw calc(20/375*100)vw calc(65/375*100)vw;

      &-primary {
        a {
          color: var(--footer-text-secondary);

          &:hover {
            text-decoration: none;
          }
        }

        &-group {
          border-bottom: calc(1/375*100)vw solid var(--footer-bgcolor-secondary);
        }

        &-title {
          font-size: calc(14/375*100)vw;
          padding: calc(17.5/375*100)vw calc(35/375*100)vw calc(15.5/375*100)vw 0;
          position: relative;
          cursor: pointer;

          &::before {
            content: '';
            width: calc(35/375*100)vw;
            height: calc(35/375*100)vw;
            position: absolute;
            top: 12%;
            right: 0;
            background: url('../image/icon-arrow-dwon-red.svg') center center no-repeat;
            background-size: calc(10.5/375*100)vw calc(5/375*100)vw;
            transition: transform var(--base-animespeed-slow);
          }

          &.is-open {
            &::before {
              transform: rotate(180deg);
            }
          }
        }

        &-occont {
          list-style: none;
          display: none;

          li {
            border-top: calc(1/375*100)vw solid var(--footer-bgcolor-secondary);
            font-size: calc(14/375*100)vw;

            a {
              padding: calc(11.5/375*100)vw calc(35/375*100)vw calc(12.5/375*100)vw calc(24/375*100)vw;
              display: block;
              position: relative;

              &::before {
                content: '';
                width: calc(35/375*100)vw;
                height: calc(35/375*100)vw;
                position: absolute;
                top: 5%;
                right: 0;
                background: url('../image/icon-arrow-dwon-red.svg') center center no-repeat;
                background-size: calc(10.5/375*100)vw calc(5/375*100)vw;
                transform: rotate(-90deg);
              }
            }
          }
        }
      }

      &-secondary {
        a {
          color: var(--footer-text-secondary);

          &:hover {
            text-decoration: none;
          }
        }

        &-links {

          &-pages {
            columns: 2;
            font-size: calc(12/375*100)vw;

            &-ul {
              list-style: none;

              li {
                margin-top: calc(17/375*100)vw;

                &:first-child {
                  margin-top: 0;
                }
              }
            }
          }

          &-tel {
            font-size: calc(18/375*100)vw;
            text-align: center;
            margin-bottom: calc(28/375*100)vw;
            p{
              font-size: calc(14/375*100)vw;
              &.tel-title{
	              margin-bottom: calc(20/375*100)vw;
	              line-height: 1;
              }
            }
            .tel-flex{
              @mixin m-flexset flex-start,nowrap;
              align-items: flex-start;
              margin-bottom: calc(6/375*100)vw;
              flex-direction: row;
              font-family: var(--font-apex-medium);
              vertical-align: middle;
              a{
	              @mixin m-flexset flex-start,nowrap;
	              align-items: center;
              }
              img{
	              width: calc(82/375*100)vw;
	              height: auto;
	              margin-right: calc(12/375*100)vw;
		          vertical-align: middle;
              }
             }
            .tel-box{
	          width: 100%;
              padding : calc(28/375*100)vw 0;
              text-align: left;
              border-bottom: calc(1/375*100)vw solid var(--footer-bgcolor-secondary);

        	&:first-child{
	            margin-right: 50px;
	            small {
	              font-size: calc(10/375*100)vw;
	              display: block;
	            }
				img{
					margin-right: 12px;
				}
	          }
	          &:nth-child(2){
	            small {
	              margin-right: calc(12/375*100)vw;
	              display: block;
	              font-size: calc(12/375*100)vw;
	              line-height: calc(18/375*100)vw;
	            }
	            a{
		            @mixin m-flexset center,nowrap;
		            align-items: center;
		            width: calc(120/375*100)vw;
		            height: calc(35/375*100)vw;
		            background-color: var(--color-primary);
		            font-size: calc(12/375*100)vw;
		            color: #fff;
	            }
	         }
        	}
            
            a {
              font-family: var(--font-apex-medium);
              letter-spacing: .025em;
            }
          }
        }

        &-logo {
          margin-top: calc(34/375*100)vw;
          text-align: center;
		  &.for-pc{
			  display: none;
		  }
		  &.for-sp{
			  display: block; 
		  }
          a {
            svg {
              width: calc(54/375*100)vw;
              height: calc(54/375*100)vw;
              fill: var(--footer-text-secondary);
            }
          }
        }
      }
    }

    &-copyright {
      font-size: calc(13/375*100)vw;
      text-align: center;
      padding: calc(14/375*100)vw 0 calc(27/375*100)vw;
    }

    &-topagetop {
      &-btn {
        background-color: var(--text-primary);
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(60/375*100)vw;
        height: calc(60/375*100)vw;
        position: absolute;
        bottom: 120%;
        right: 0;

        svg {
          fill: var(--button-text-hover);
          transform: rotate(-90deg);
          wdith: calc(16/375*100)vw;
          height: calc(29/375*100)vw;
        }
      }
    }
  }

  /* フッターコンタクト ================================================ */
  &-footercontacts {
    position: fixed;
    width: 100%;
    bottom: calc(-145/375*100)vw;
    right: 0;
    background-color: var(--color-primary);
    z-index: 999;
    transition: bottom var(--base-animespeed);

    &.is-shown {
      bottom: 0;
    }

    &-inner {
      @mixin m-flexset;
      list-style: none;
      align-items: baseline;
      padding: 0 calc(10/375*100)vw;

      a {
        color: var(--text-tertiary);
        display: block;
        font-size: calc(10/375*100)vw;
        font-weight: bold;
        text-align: center;
        padding: calc(12/375*100)vw 0;

        svg {
          fill: var(--text-tertiary);
          width: calc(24/375*100)vw;
          height: calc(24/375*100)vw;
          margin-bottom: calc(8/375*100)vw;
        }
      }
    }

    &-li {
      @mixin m-flexbase calc(90/375*100)vw;
    }
  }

  /* ボディー部分 */
}

/* ユーティリティ郡 ================================================ */

.u {
  /* タグ一覧 */
  &-aot {
    &:after {
      content: "art of taste";
    }
  }
  &-aw {
    &:after {
      content: "alfawoman";
    }
  }
  &-by {
    &:after {
      content: "be yourself.";
    }
  }
  &-ce {
    &:after {
      content: "car event";
    }
  }
  &-cm {
    &:after {
      content: "tv cm";
    }
  }
  &-cp {
    &:after {
      content: "campaign";
    }
  }
  &-ev {
    &:after {
      content: "event";
    }
  }
  &-f1 {
    &:after {
      content: "Alfa Romeo Racing";
    }
  }
  &-hrt {
    &:after {
      content: "heritage";
    }
  }
  &-imp {
    &:after {
      content: "impression";
    }
  }
  &-ma {
    &:after {
      content: "mondo alfa";
    }
  }
  &-mv {
    &:after {
      content: "movie";
    }
  }
  &-ne {
    &:after {
      content: "news";
    }
  }
  &-oi {
    &:after {
      content: "owner interview";
    }
  }
  &-prod {
    &:after {
      content : "product";
    }
  }
  &-sp {
    &:after {
      content: "sport";
    }
  }
  /**/
  &-text {
    &-white {
      color: #fff !important;
    }
  }
  &-font {
    &-apex {
      &-medium {
        font-family: var(--font-apex-medium);
      }
    }
  }
  &-width780 {
    width: calc(255/375*100)vw;
    padding: 0 calc(20/375*100)vw;
  }
  &-margin {
    &-none {
      margin: 0 !important;
    }
  }

  /* モデルバナー（トップページ仕様） */
  &-models {
    &-title {
      text-align: center;
      font-family: var(--font-apex-medium);
      font-size: calc(32/375*100vw);
      font-weight: normal;
      letter-spacing: .05em;
    }
    &-list {
      margin-top: calc(30/375*100vw);
      &-link {
        display: block;
        position: relative;
      }
    }
    &-item {
      &-link {
        display: block;
        position: relative;
      }

      &-image {
        line-height: 0;
        position: relative;
        width: 100%;
        overflow: hidden;
        display: block;
        img {
          width: 100%;
          display: block;
        }
      }

      &-text {
        padding: calc(20/375*100vw);
        color: var(--button-text-hover);
        position: absolute;
        bottom: 0;
        left: 0;
        line-height: 1;

        &-type {
          font-size: calc(12/375*100vw);

        }

        &-model {
          font-size: calc(24/375*100vw);
          font-family: 'sequel';
          margin-top: calc(5/375*100vw);
          letter-spacing: .05em;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .new {
            width: 100%;
            line-height: 1.2;
            display: block;
            &:after {
              content: "new";
              text-transform: uppercase;
              color: var(--color-primary);
              font-size: 76%;
            }
          }
        }

        &-catch,
        &-spec {
          display: none;
        }

        &-discon {
          font-size: calc(12/375*100)vw;
          margin-left: calc(15/375*100)vw;
          letter-spacing: 0;
        }

        &-btn {
          @mixin m-more-btn-small-hover var(--button-text-hover) , transparent;
          font-size: calc(12/375*100)vw;
          width: calc(130/375*100)vw;
          text-align: left;
          margin-top: calc(5/375*100)vw;

          span {
            font-family: var(--font-apex-book);
            letter-spacing: .1em;
          }

          i {
            right: 15%;
          }

        }
      }

    }
  }
}

/* カルーセル */
.u-carousel-kv {
  /* height: calc(100vh - 64/375*100)vw); */
  /* height: 100vh - calc(64/375*100)vw; */
  height: calc(var(--vh, 1vh) * 100- calc(64/375*100vw));
  max-height: 1000px;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--text-primary);
  }

  &::after {
    /* loading */
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -100px;
    width: 200px;
    height: 2px;
    background-color: var(--color-primary);
    animation: kv-loading 2s infinite;
  }

  &.slick-initialized {
    &::after {
      display: none;
    }
  }

  @keyframes kv-loading {
    0% {
      transform: scaleX(0);
    }

    50% {
      transform: scaleX(1);
    }

    100% {
      transform: scaleX(0);
    }
  }

  &-single {
    /* height: calc(100vh - 64/375*100)vw); */
    height: calc(var(--vh, 1vh) * 100- calc(64/375*100vw));
    max-height: 1000px;
    background-size: 100% auto;
    /* background-size: cover; */
    background-position: center top;
    background-repeat: no-repeat;
    position: relative;
    &.type-white,
    &.type-white2 {
      background-color: #fff;
      &:after {
        content: "";
        width: 100%;
        height: calc(150/375*100vw);
        background: linear-gradient(0deg, rgba(0,0,0,.5) 0%, rgba(0,0,0,0) 100%);
        @mixin m-absolute auto,auto,0,0;
      }
    }
  }

  &-text {
    position: absolute;
    bottom: 15%;
    left: 0;
    width: 100%;
    @mixin m-innersp;
  }

  &-link {
    color: var(--header-textcolor-secondary);
    display: block;
    .type-white & {
      color: var(--text-primary);
    }
    .type-white2 & {
      color: var(--text-primary);
    }
  }

  &-catch {
    font-family: var(--font-tsukushi);
    font-size: calc(24/375*100)vw;
    line-height: 1.333333333;
    transform: translate(0, 160%);
    transition: transform 2s .4s, opacity 2s .4s;
    opacity: 0;
    font-feature-settings: "palt"1;
    text-shadow: 1px 1px 2px black;
    .is-active & {
      transform: translate(0, 0);
      opacity: 1;
    }
    .type-white & {
      text-shadow: 1px 1px 2px white;
      font-weight: bold;
    }
    .type-white2 & {
      text-shadow: none;
      font-weight: bold;
    }
  }

  &-lead {
    margin-top: calc(23/375*100)vw;
    line-height: 1.375;
    transform: translate(0, 260%);
    transition: transform 2s .8s, opacity 2s .8s;
    opacity: 0;
    text-shadow: 1px 1px 2px black;
    .is-active & {
      transform: translate(0, 0);
      opacity: 1;
    }
    .type-white & {
      text-shadow: 1px 1px 2px white;
      /* font-weight: bold; */
    }
    .type-white2 & {
      text-shadow: none;
    }
  }

  &-btn {
    display: none;
  }
}

.u-prev-arrow {
  padding: calc(25/375*100)vw calc(15/375*100)vw;
  position: absolute;
  left: 3%;
  bottom: 0;
  z-index: 9;

  svg {
    transform: rotate(180deg);
    width: calc(16/375*100)vw;
    height: calc(29/375*100)vw;
    fill: var(--button-text-hover);
  }
}

.u-next-arrow {
  padding: calc(25/375*100)vw calc(15/375*100)vw;
  position: absolute;
  right: 3%;
  bottom: 0;
  z-index: 10;

  svg {
    width: calc(16/375*100)vw;
    height: calc(29/375*100)vw;
    fill: var(--button-text-hover);
  }
}

.slick-dots {
  position: relative;
  display: inline-block;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: calc(30/375*100)vw;
  text-align: center;

  &>li {
    display: inline-block;
    position: relative;
    text-align: center;
    width: calc(14/375*100)vw;
    margin: 0 calc(4/375*100)vw;
  }

  svg {
    width: calc(14/375*100)vw;
    height: calc(14/375*100)vw;
    stroke: var(--color-primary);
    stroke-dasharray: 54;
    stroke-dashoffset: 54;
    transition: stroke-dashoffset 10s linear;
    transform: scale(0.4);
    stroke-width: 0;
    fill: #fff;

    &.is-active {
      stroke-dashoffset: 0;
      fill: none;
      stroke-width: 2;
      transform: scale(1) rotate(-90deg);

      &:hover {
        transform: scale(1) rotate(-90deg);
      }
    }

    &:hover {
      transform: scale(0.6);
    }
  }
}

.u-kvcover {
  position: relative;
}

.u-kvscroll {
  display: none;
}

/* ニュース ================================================ */
.u-news {
  &-content {
    border-bottom: calc(1/375*100)vw solid var(--line-primary);
    margin-top: calc(20/375*100)vw;
    padding-bottom: calc(30/375*100)vw;

    &-link {
      display: block;
      color: var(--text-primary);
    }

    &-main {
      @mixin m-flexset;
    }

    &-imagebox {
      @mixin m-flexbase calc(157/375*100)vw;
      margin-right: calc(20/375*100)vw;
      position: relative;
      img {
        width: 100%;
        position: relative;
      }
      span {
        @mixin m-absolute auto,0,calc(10/375*100vw),auto;
        z-index: 10;
        font-size: calc(12/375*100vw);
        line-height: 1.5;
      }
    }

    &-textbox {
      flex: 1;

      &-tags {
        list-style: none;

        &-tag {
          font-family: var(--font-apex-medium);
          font-size: calc(9/375*100)vw;
          border: 1px solid var(--text-primary);
          border-radius: calc(3/375*100)vw;
          display: inline-block;
          padding: calc(5/375*100)vw calc(10/375*100)vw calc(1/375*100)vw;
          margin-top: calc(5/375*100)vw;
          letter-spacing: .1em;
          text-transform: uppercase;
          &:after {
            display:block;
          }
        }
      }

      &-text {
        font-family: var(--font-tsukushi);
        font-feature-settings: "palt"1;
        line-height: 1.375;
        margin-top: calc(10/375*100)vw;
        font-weight: normal;
      }

      &-date {
        font-family: var(--font-apex-book);
        font-size: calc(12/375*100)vw;
        color: var(--text-heading);
        margin-top: calc(12/375*100)vw;
        letter-spacing: .1em;
      }
    }

    &-btncover {
      text-align: center;
    }

    &-btn {
      @mixin m-more-btn-small-hover var(--color-primary), var(--button-text-hover);
      margin-top: calc(18/375*100)vw;
      width: calc(125/375*100)vw;
      font-size: calc(12/375*100)vw;

      span {
        font-family: var(--font-apex-book);
        letter-spacing: .1em;
      }
    }
  }
}

/* 動画 ================================================ */
.u-movies {
  &-article {}

  &-content {
    margin-top: calc(30/375*100)vw;

    &-link {
      display: block;
      color: var(--text-primary);
    }

    &-main {}

    &-imagebox {
      position: relative;

      img {
        width: 100%;
      }

      &::after {
        content: '';
        background: url(../image/icon-play.svg) center no-repeat;
        background-size: calc(51/375*100)vw;
        width: calc(51/375*100)vw;
        height: calc(51/375*100)vw;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: calc(-25.5/375*100)vw 0 0 calc(-25.5/375*100)vw;
      }
    }

    &-textbox {
      background-color: var(--bgcolor-primary);
      margin: calc(-20/375*100)vw auto 0;
      padding: calc(20/375*100)vw calc(20/375*100)vw 0;
      position: relative;
      width: calc(278/375*100)vw;

      &-tags {
        list-style: none;

        &-tag {
          font-family: var(--font-apex-medium);
          font-size: calc(9/375*100)vw;
          border: 1px solid var(--text-primary);
          border-radius: calc(3/375*100)vw;
          display: inline-block;
          padding: calc(5/375*100)vw calc(10/375*100)vw calc(1/375*100)vw;
          margin-top: calc(5/375*100)vw;
          letter-spacing: .1em;
          text-transform: uppercase;
        }
      }

      &-text {
        font-family: var(--font-tsukushi);
        font-feature-settings: "palt"1;
        line-height: 1.375;
        margin-top: calc(10/375*100)vw;
      }
    }

    &-btncover {
      text-align: center;
    }

    &-btn {
      @mixin m-more-btn-small-hover var(--color-primary), var(--button-text-hover);
      margin-top: calc(15/375*100)vw;
      width: calc(125/375*100)vw;
      font-size: calc(12/375*100)vw;

      span {
        font-family: var(--font-apex-book);
        letter-spacing: .1em;
      }
    }
  }
}

/* 通常ページのカバー ==================================== */
.u-normal-cover {
  @mixin m-innersp;
  margin-bottom: calc(80/375*100)vw;

  a {
    color: var(--color-primary);
  }
}
.u-default-container {
  @mixin m-innersp;
}

/* パンくず ==================================== */
.u-pankuzu,
.u-breadcrumb {
  font-size: calc(12/375*100)vw;
  margin-top: calc(10/375*100)vw;
  overflow: scroll;

  ul {
    @mixin m-flexset flex-start ;

    li {
      list-style: none;
      white-space: nowrap;

      &::before {
        content: '>';
        margin: 0 calc(5/375*100)vw;
      }

      &:first-child::before {
        display: none;
      }
    }

    a {
      color: var(--text-primary);
    }
  }
}
.u-breadcrumb {
  @mixin m-absolute 0,auto,auto,0;
  @mixin m-innersp;
}

/* ページタイトル ==================================== */
.u-pagetitle {
  margin-top: calc(20/375*100)vw;

  &-text {
    font-size: calc(24/375*100)vw;
    line-height: 1.333333333;
    /*font-family: var(--font-tsukushi);*/
    font-family: "Helvetica Neue",Arial,"Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
    font-feature-settings: "palt"1;
    font-weight: normal;
  }
}

/* ページ内囲み ==================================== */
.u-pagecontent {
  margin-top: calc(30/375*100)vw;
  word-break: break-all;

  a {
    color: var(--color-primary);
  }
}

/* 利用規約などテキストのみのページスタイル ==================================== */
.u-textcontent {
  line-height: 1.375;

  h2, h3, h4 {
    margin-top: 2em;
    margin-bottom: 1em;
  }

  p {
    margin-top: 1em;
  }

  &-list {
    &-primary {
      margin-left: 1.5em;

      li {
        margin-top: 1em;
      }
    }
  }
}

/* ページスタイル マージンなし lineheight指定なし ==================================== */
.u-basiccontent {
  h2 {
    font-size: calc(20/375*100)vw;
    /*font-family: var(--font-tsukushi);*/
    font-family: "Helvetica Neue",Arial,"Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
    font-feature-settings: "palt"1;
    font-weight: normal;
    line-height: 1.4;
  }

  h3 {
    font-size: calc(18/375*100)vw;
    /*font-family: var(--font-tsukushi);*/
    font-family: "Helvetica Neue",Arial,"Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
    font-feature-settings: "palt"1;
    font-weight: normal;
    line-height: 1.333333333;
  }

  h4 {
    /*font-family: var(--font-tsukushi);*/
    font-family: "Helvetica Neue",Arial,"Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
    font-feature-settings: "palt"1;
    font-weight: normal;
    line-height: 1.5;
  }

  &-addmargin {
    /* マージン追加したスタイル */
    line-height: 1.375;

    h2 {
      font-size: calc(20/375*100)vw;
      /*font-family: var(--font-tsukushi);*/
    font-family: "Helvetica Neue",Arial,"Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
      font-feature-settings: "palt"1;
      font-weight: normal;
      margin-top: 2em;
      margin-bottom: 1em;
    }

    h3 {
      font-size: calc(18/375*100)vw;
      /*font-family: var(--font-tsukushi);*/
    font-family: "Helvetica Neue",Arial,"Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
      font-feature-settings: "palt"1;
      font-weight: normal;
      margin-top: 2em;
      margin-bottom: 1em;
    }

    h4 {
      /*font-family: var(--font-tsukushi);*/
    font-family: "Helvetica Neue",Arial,"Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
      font-feature-settings: "palt"1;
      font-weight: normal;
      margin-top: 2em;
      margin-bottom: 1em;
    }

    p {
      margin-top: 1em;
    }
  }
}

/* テーブルデザイン ===================== */
.u-table-style-primary {
  color: var(--text-secondary);
  font-size: calc(12/375*100)vw;
  line-height: 1.2;
  min-width: 100%;

  thead {
    th {
      border-bottom: 2px solid var(--table-head-bordercolor);
      text-align: left;
      padding: calc(15/375*100)vw calc(15/375*100)vw calc(15/375*100)vw 0;
      white-space: nowrap;
      font-weight: normal;
    }
  }

  tbody {
    th {
      border-bottom: 1px solid var(--table-body-bordercolor);
      padding: calc(8/375*100)vw calc(8/375*100)vw calc(8/375*100)vw 0;
      vertical-align: middle;
      text-align: left;
      font-weight: normal;
      white-space: nowrap;
    }

    td {
      border-bottom: 1px solid var(--table-body-bordercolor);
      padding: calc(8/375*100)vw calc(8/375*100)vw calc(8/375*100)vw 0;
      vertical-align: middle;
      text-align: right;
    }
  }
}

/* アンカーナビ =====================*/
.u-unckernavi {
  display: none;
}

/* Slick 上書き ======================================================*/
.slick-dotted.slick-slider {
  margin-bottom: 0;
}

/* modaal 上書き ======================================================*/
.modaal-close {
  background: rgba(0, 0, 0, .1);
  border-radius: 0;

  &:hover {
    background: rgba(0, 0, 0, .1);
    transform: rotate(90deg);

    &::before, &::after {
      background-color: var(--button-text-hover);
    }
  }

  &::before, &::after {
    border-radius: 0;
    width: 1px;
    height: 30px;
    top: 10px;
    left: 25px;
  }
}

.modaal-gallery-control {
  border-radius: 0;
  height: 210px;
  background-color: rgba(0, 0, 0, .1);

  &:hover {
    background-color: rgba(0, 0, 0, .1);

    &::before, &::after {
      background-color: var(--button-text-hover);
    }
  }

  &::before, &::after {
    border-radius: 0;
    width: 1px;
    top: 43%;
    height: 30px;
  }
}

.modaal-gallery-prev {
  &::before {
    margin-top: 11px;
  }

  &::after {
    margin-top: -10px;
  }
}

.modaal-gallery-next {
  &::before {
    margin-top: -11px;
  }

  &::after {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 1140px) {
  .modaal-gallery-control {
    top: 50%;
    bottom: auto;
    transform: none;
    background: rgba(0, 0, 0, .1);
    margin-top: -35px;
    height: 70px;

    &::before, &::after {
      top: 28%;
    }
  }

  .modaal-gallery-prev {
    left: 0;
  }

  .modaal-gallery-next {
    right: 0;
  }
}

.modaal-video-container {
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: var(--color-primary);
    transition: width var(--base-animespeed-slow);
    position: absolute;
    top: 0;
    left: 0;
  }

  &.is-open {
    &::after {
      width: 0;
    }
  }

  &.is-open {
    width: 100%;
  }
}

/* プロダクト系 ================================================ */
.u-product {
  line-height: 1.5625;

  a {
    color: var(--color-primary);
  }

  &-contain {
    position: relative;
  }
  &-kv {
    height: calc(280/375*100)vw;
    margin-top: calc(60/375*100)vw;

    &-inner {
      position: relative;
      height: calc(280/375*100)vw;
    }
    &-banner {
      display: block;
      overflow: hidden;
      background: var(--color-primary);
      /* font-weight: bold; */
      line-height: 1.4;
      padding: .8em calc(20/375*100)vw;
      font-feature-settings: "palt"1;
      font-size: calc(18/375*100)vw;
      small {
        font-weight: normal;
        margin: 0;
      }
      strong {
        font-weight: bold;
      }
      &-center {
        text-align: center;
      }
      &-title {
        font-size: calc(24/375*100vw);
        font-feature-settings: unset;
        font-family: var(--font-tsukushi);
      }
      &-title2 {
        font-size: calc(24/375*100vw);
        font-feature-settings: unset;
        font-family: var(--font-tsukushi);
        padding-bottom: .5em;
        margin-bottom: calc(16/375*100vw);
        position: relative;
        &:after {
          content: "";
          display: block;
          width: calc(57/375*100vw);
          height: 1px;
          background: #fff;
          @mixin m-absolute auto,auto,0,50%;
          transform: translateX(-50%);
        }
      }
      &-period {
        font-size: calc(18/375*100vw);
        font-family: var(--font-tsukushi);

        span {
          font-size: calc(32/375*100vw);
        }
        big {
          font-size: calc(36/375*100vw);
        }
      }
      &-lead {
        font-size: calc(19/375*100vw);
        font-family: var(--font-tsukushi);
        position: relative;
        padding-top: 1em;
        margin-top: calc(10/375*100vw);

        &:before {
          content: "";
          display: block;
          width: calc(57/375*100vw);
          height: 1px;
          background: #fff;
          @mixin m-absolute 0,auto,auto,50%;
          transform: translateX(-50%);
        }

        big {
          display: block;
          font-size: calc(37/375*100vw);
          font-feature-settings: "palt"1;
        }
      }
      &-button {
        @mixin m-more-btn-small-hover #fff,transparent;
        width: calc(120/375*100vw);
        text-align: left;
      }
      &-offer {
        @mixin m-more-btn-large-hover #fff,var(--color-primary);
        display: block;
        width: 100%;
        font-size: calc(16/375*100vw);
        background: var(--color-primary);
        border-color: var(--color-primary);
        padding-left: 5%;
        text-align: left;
      }
    }
  }

  &-navi {
    background-color: var(--bgcolor-secondary);
    margin-top: calc(-60/375*100)vw;
    width: 100%;
    z-index: 9;
    position: absolute;
    height: calc(60/375*100)vw;
    transition: all var(--base-animespeed-slow);

    &.is-open {
      height: auto;
    }

    &.is-hide {
      position: fixed;
      top: 0;
      left: 0;
    }

    &.is-shown {
      position: fixed;
      top: 0;
      left: 0;
      margin-top: 0;
      z-index: 999;
    }

    .u-pankuzu {
      padding: 0 calc(20/375*100)vw 0;
      margin-top: calc(7/375*100)vw;
      margin-bottom: calc(5/375*100)vw;
    }

    &-title {
      padding: 0 calc(20/375*100)vw calc(11/375*100)vw;
      position: relative;
      margin-top: 0;

      .is-open & {
        margin-top: calc(23/375*100)vw;

        a {
          align-items: center;
          display: flex;
          position: relative;
          height: calc(35/375*100)vw;


          &::before {
            content: '';
            width: calc(35/375*100)vw;
            height: calc(35/375*100)vw;
            position: absolute;
            top: 0;
            right: -3%;
            background: url('/common/image/icon-arrow-dwon-red.svg') center center no-repeat;
            background-size: calc(10/375*100)vw calc(5/375*100)vw;
            transform: rotate(-90deg);
          }
        }
      }

      svg {
        fill: var(--text-primary);
        width: calc(122/375*100)vw;
      }
    }

    &-openbutton {
      width: calc(30/375*100)vw;
      height: calc(30/375*100)vw;
      position: absolute;
      bottom: 0;
      right: 0;
      margin-top: calc(-5/375*100)vw;
      margin-right: calc(20/375*100)vw;
      background: url('/common/image/icon-arrow-dwon-red.svg') center center no-repeat;
      background-size: calc(10/375*100)vw calc(5/375*100)vw;
      transition: transform var(--base-animespeed-slow);
    }

    &-closebutton {
      width: calc(30/375*100)vw;
      height: calc(30/375*100)vw;
      position: absolute;
      top: calc(15/375*100)vw;
      right: 0;
      margin-top: calc(-5/375*100)vw;
      margin-right: calc(20/375*100)vw;
      background: url('/common/image/icon-close.svg') center center no-repeat;
      background-size: calc(24/375*100)vw calc(24/375*100)vw;
      transition: transform var(--base-animespeed-slow);
      display: none;
    }

    &-list {
      padding: 0 calc(20/375*100)vw calc(40/375*100)vw;
      list-style: none;
      display: none;

      li {
        border-bottom: 1px solid var(--line-primary);

        &:first-child {
          border-top: 1px solid var(--line-primary);
        }

        a {
          display: block;
          padding: calc(15/375*100)vw 0 calc(15/375*100)vw calc(10/375*100)vw;
          position: relative;
          color: var(--text-primary);

          &::before {
            content: '';
            width: calc(35/375*100)vw;
            height: calc(35/375*100)vw;
            position: absolute;
            top: 15%;
            right: -3%;
            background: url('/common/image/icon-arrow-dwon-red.svg') center center no-repeat;
            background-size: calc(10/375*100)vw calc(5/375*100)vw;
            transform: rotate(-90deg);
          }
        }
      }
    }
  }

  &-container {
    &-alpha {
      padding: calc(20/375*100)vw;

      &-leadbox {
        margin: calc(-20/375*100)vw calc(-20/375*100)vw 0;
        padding: calc(40/375*100)vw calc(20/375*100)vw calc(60/375*100)vw;
        text-align: left;
        font-feature-settings: "palt"1;
      }
    }

    &-why {
      margin-top: calc(40/375*100)vw;
    }
  }

  &-link {
    &-full {
      font-feature-settings: "palt";
      &-button {
        @mixin m-more-btn-large-hover;
        width: 100%;
      }
    }
  }

  &-catch {
    font-family: var(--font-tsukushi);
    font-size: calc(20/375*100)vw;
    line-height: 1.4;
  }

  &-lead {
    font-family: var(--font-tsukushi);
    line-height: 1.5625;
    margin-top: calc(40/375*100)vw;
  }

  &-feature {
    padding-top: calc(40/375*100)vw;

    dl {
      @mixin m-flexset center, no-wrap;
      margin-top: calc(20/375*100)vw;
      align-items: center;
    }

    dt {
      @mixin m-flexbase calc(88/375*100)vw;
      line-height: 0;

      img {
        width: calc(88/375*100)vw;
        height: auto;
      }
    }

    dd {
      @mixin m-flexbase calc(122/375*100)vw;
      font-size: calc(12/375*100)vw;
      line-height: 1.5;
    }
  }

  &-variation {
    margin-top: calc(40/375*100)vw;

    &-navi {
      border-bottom: 1px solid var(--text-primary);

      &-cover {
        overflow-x: scroll;
        transform: translate(0, 1px);
      }

      ul {
        @mixin m-flexset;
        line-height: 0;

        li {
          font-family: var(--font-apex-book),var(--base-font);
          font-size: calc(14/375*100)vw;
          margin: 0 calc(5/375*100)vw;
          list-style: none;
          line-height: 1.666666667;
          text-align: center;
          @mixin m-flexset center,nowrap;
          align-items: flex-end;
          a {
            color: var(--text-primary);
            display: block;
            padding: calc(15/375*100)vw calc(5/375*100)vw calc(5/375*100)vw;
            white-space: nowrap;
            transition: all var(--base-animespeed);
            position: relative;

            &::before {
              content: '';
              height: 2px;
              background-color: var(--color-primary);
              width: 0;
              position: absolute;
              left: 0;
              bottom: 0;
              transition: all var(--base-animespeed);
            }

            &.is-active {
              color: var(--color-primary);

              &::before {
                width: 100%;
              }
            }
          }
        }
      }
    }

    &-contents {
      padding: calc(30/375*100)vw 0 0;
    }

    &-name {
      font-family: var(--font-apex-book);
      font-size: calc(18/375*100)vw;
      line-height: 1.4;
      font-weight: normal;

      &-limit {
        font-weight: bold;
        font-family: var(--base-font);
        margin-bottom: calc(20/375*100vw);
        display: block;
      }
      &-brand {
        font-family: var(--font-apex-medium);
      }
      &-jp {
        font-family: var(--base-font);
        font-size: calc(12/375*100vw);
        display: block;
      }
    }

    &-color {
      font-size: calc(12/375*100vw);
      margin: calc(10/375*100vw) 0;
      dl {
        @mixin m-flexset flex-start,nowrap;
        align-items: center;
        margin-bottom: calc(5/375*100vw);
      }
      &-type:after {
        content: "：";
      }
      &-name {
        @mixin m-flexset flex-start,nowrap;
        align-items: center;
        span {
          width: calc(14/375*100vw);
          height: calc(14/375*100vw);
          border-radius: 100%;
          display: block;
          margin: 0 calc(5/375*100vw);
        }
      }
    }
    &-catch {
      margin-top: calc(20/375*100)vw;
      /*font-family: var(--font-tsukushi);*/
    font-family: "Helvetica Neue",Arial,"Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
      font-feature-settings: "palt"1;
      line-height: 1.5625;
    }

    &-feature {
      margin-top: calc(30/375*100)vw;
      font-size: calc(12/375*100)vw;
      line-height: 1.2;
      min-width: 100%;
      border-top: 1px solid var(--table-body-bordercolor);

      tbody {
        td {
          border-bottom: 1px solid var(--table-body-bordercolor);
          padding: calc(12/375*100)vw 0;
          vertical-align: middle;
          text-align: left;
        }

        tr td:nth-child(2) {
          border-left: 1px solid var(--table-body-bordercolor);
          padding-left: calc(6/375*100)vw;
        }
      }
    }

    &-feature02 {
      margin-top: calc(30/375*100)vw;

      &-list {
        @mixin m-flexset;

        dt {
          font-size: calc(10/375*100)vw;
          text-align: center;
        }

        dd {
          font-size: calc(20/375*100)vw;
          font-family: var(--font-apex-book);
          text-align: center;

          span {
            font-size: calc(12/375*100)vw;
            margin-left: .2em;
          }
        }

      }

      &-note {
        text-align: right;
        font-size: calc(12/375*100)vw;
        margin-top: calc(20/375*100)vw;
      }
    }

    &-size {
      font-size: calc(12/375*100)vw;
      margin-top: 2em;
    }

    &-slick {
      width: calc(280/375*100)vw;
      margin: calc(10/375*100)vw auto 0;

      .u-product-slide-arrow {
        top: calc(70/375*100)vw;

        span {
          display: none;
        }
      }

      .u-product-prev-arrow {
        left: calc(-30/375*100)vw;
      }

      .u-product-next-arrow {
        right: calc(-30/375*100)vw;
      }

      &-content {
        &-image {
          ul {
            list-style: none;
            position: relative;
            height: calc(145/375*100)vw;
          }

          li {
            position: absolute;
            top: 0;
            left: 0;
          }

          img {
            width: 100%;
          }
        }

        &-colors {
          ul {
            text-align: center;
            list-style: none;
            @mixin m-flexset center;
          }

          li {
            text-indent: -99999px;
            display: inline-block;
            width: calc(18/375*100)vw;
            height: calc(18/375*100)vw;
            background-color: var(--color-primary);
            border-radius: 50%;
            margin: 0 calc(8/375*100)vw;
            cursor: pointer;
            transition: all var(--base-animespeed);

            br {display: none;}

          }
        }

        &-colortext {
          text-align: center;
          font-size: calc(14/375*100)vw;
          margin-top: .5em;

          small {
            font-size: calc(12/375*100)vw;
            display: block;
          }
        }
      }
    }

    &-colorarea {
      min-height: calc(90/375*100)vw;
      margin-top: calc(5/375*100)vw;
    }

    &-buttons {
      margin-top: calc(20/375*100)vw;

      &-button {
        margin-top: calc(20/375*100)vw;
        @mixin m-more-btn-large-hover;
        width: 100%;
      }
      &-caution {
        display: block;
        margin-top: calc(10/375*100vw);
        font-size: calc(12/375*100vw);
      }
    }
  }

  &-newmark {
    color: var(--color-primary);
    margin-right: .2em;
  }

  /* why */
  &-why {
    &-header {
      padding: calc(20/375*100)vw;

      &-title {
        text-align: center;
        font-size: calc(32/375*100)vw;
        font-family: var(--font-apex-medium);
        font-weight: normal;
      }

      &-lead {
        margin-top: 1em;
        /*font-family: var(--font-tsukushi);*/
    font-family: "Helvetica Neue",Arial,"Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
        font-feature-settings: "palt"1;
      }
    }

    &-links {
      margin-top: calc(20/375*100)vw;

      a {
        display: block;
      }
    }

    &-text {
      background-color: var(--text-secondary);
      color: var(--button-text-hover);

      &-alpha {
        padding: calc(20/375*100)vw;
        height: calc(210/375*100)vw;
        background-size: cover;
        @mixin m-flexset flex-end;
        flex-flow: column;
      }

      &-beta {
        padding: calc(20/375*100)vw calc(20/375*100)vw calc(40/375*100)vw;
        background-size: cover;
        background-position: right top;
      }

      &-category {
        font-weight: bold;

        &2 {
          font-weight: bold;
          span {
            color: var(--color-primary);
            display: block;
            line-height: 1;
          }
        }
      }

      &-catch {
        font-size: calc(18/375*100)vw;
        /*font-family: var(--font-tsukushi);*/
    font-family: "Helvetica Neue",Arial,"Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
        margin-top: .2em;
        line-height: 1.333333333;
      }

      &-description {
        font-size: calc(14/375*100)vw;

        &2 {
          /*font-family: var(--font-tsukushi);*/
    font-family: "Helvetica Neue",Arial,"Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
          font-feature-settings: "palt"1;
        }
      }

      &-detail {
        font-size: calc(14/375*100)vw;
        margin: calc(30/375*100)vw 0 0;
        &-price {
          display:block;
          font-size: calc(20/375*100)vw;
          font-family: var(--font-apex-book);
          &:after {
            font-size: calc(14/375*100)vw;
            content: "円～";
          }
        }
      }

      &-button {
        margin-top: calc(20/375*100)vw;
        @mixin m-more-btn-small-hover var(--button-text-hover), var(--color-primary);
        width: calc(140/375*100)vw;
        text-align: center;
        padding: calc(9/375*100)vw calc(40/375*100)vw calc(9/375*100)vw calc(20/375*100)vw;

        span {
          font-family: var(--font-apex-book);
          letter-spacing: .1em;
        }

        i {
          right: 5%;
        }

        &2 {
          margin-top: calc(20/375*100)vw;
          @mixin m-more-btn-small-hover var(--color-primary), var(--button-text-hover), transparent;
          width: calc(140/375*100)vw;
          /* text-align: center; */
          padding: calc(9/375*100)vw calc(40/375*100)vw calc(9/375*100)vw calc(20/375*100)vw;

          span {
            font-family: var(--font-apex-book);
            letter-spacing: .1em;
          }

          i {
            right: 5%;
          }
        }
      }

      &-name {
        font-size: calc(20/375*100)vw;
        margin-top: .3em;
        font-family: var(--font-apex-book);
        line-height: 1.6;
      }
    }

    &-add, &-others {
      margin-top: calc(40/375*100)vw;

      .u-product-why-text-alpha {
        height: calc(160/375*100)vw;
        padding: calc(7/375*100)vw calc(20/375*100)vw;
      }
    }

    &-others {
      .u-product-why-text-beta {
        padding: calc(7/375*100)vw calc(20/375*100)vw calc(40/375*100)vw;
      }
    }

    &-image {
      display: none;
    }
  }

  /* ムービー */
  &-movies {
    padding: calc(80/375*100)vw calc(20/375*100)vw calc(40/375*100)vw;

    &-title {
      text-align: center;
      font-family: var(--font-apex-medium);
      font-size: calc(32/375*100)vw;
      font-weight: normal;
      letter-spacing: .05em;
    }

    &-article {
      margin: calc(10/375*100)vw calc(-20/375*100)vw 0;

      a {
        color: var(--text-primary);
      }
    }

    .u-movies-content {
      width: calc(255/375*100)vw;
      padding: 0 calc(20/375*100)vw;
      margin-top: 0;
    }

    .u-movies-content-textbox {
      width: 100%;
      margin: 0;
      padding: calc(10/375*100)vw 0 0;
    }

    .u-movies-content-btncover {
      display: none;
    }

    .u-product-prev-arrow {
      left: calc(30/375*100)vw;
      top: calc(89/375*100)vw;
    }

    .u-product-next-arrow {
      right: calc(30/375*100)vw;
      top: calc(89/375*100)vw;
    }

    &-morebutton {
      &-cover {
        margin: calc(40/375*100)vw calc(30/375*100)vw calc(20/375*100)vw;
      }

      &-button {
        @mixin m-more-btn-large-hover;
        width: 100%;
        font-family: var(--font-apex-medium);
        letter-spacing: .1em;
      }
    }
  }

  /* gallery */
  &-gallery {
    padding: calc(40/375*100)vw calc(20/375*100)vw calc(40/375*100)vw;

    &-title {
      text-align: center;
      font-family: var(--font-apex-medium);
      font-size: calc(32/375*100)vw;
      font-weight: normal;
      letter-spacing: .05em;
    }

    &-cover {
      display: none;
    }

    &slick {
      margin: calc(20/375*100)vw calc(-20/375*100)vw 0;
    }

    &-item {
      width: calc(255/375*100)vw;
      padding: 0 calc(20/375*100)vw;

      img {
        width: 100%;
        height: auto;
      }
    }

    .u-product-prev-arrow {
      left: calc(30/375*100)vw;
      top: calc(85/375*100)vw;
    }

    .u-product-next-arrow {
      right: calc(30/375*100)vw;
      top: calc(85/375*100)vw;
    }
  }

  /* campaign */
  &-campaign {
    padding: calc(40/375*100vw) 0 calc(30/375*100vw);
    &-banner {
      display: block;
      background: var(--bgcolor-secondary);
      margin: 0 calc(-20/375*100vw);
      color: #000 !important;
      &-detail {
        padding: calc(20/375*100vw) calc(20/375*100vw) calc(30/375*100vw);
        &-title {
          font-family: var(--font-tsukushi);
          font-size: calc(24/375*100vw);
          font-weight: normal;
          span {
            font-size: calc(18/375*100vw);
            display: block;
          }
          small {
            font-size: calc(16/375*100vw);
            display: block;
          }
        }
        &-term {
          font-family: var(--font-tsukushi);
          font-size: calc(18/375*100vw);
          margin: 0 0 calc(20/375*100vw);
        }
        &-description {
          margin-bottom: calc(20/375*100vw);
        }
        &-button {
          @mixin m-more-btn-small-hover #fff,var(--color-primary);
          width: calc(120/375*100vw);
        }
      }
      &-image,img {
        display: block;
        width: 100%;
      }
    }
  }

  /* support */
  &-support {
    /* padding: calc(40/375*100)vw 0 calc(40/375*100)vw; */
    padding-bottom: calc(40/375*100)vw;

    .u-default-container {
      padding: 0;
    }

    &-title {
      text-align: center;
      font-family: var(--font-apex-medium);
      font-size: calc(32/375*100)vw;
      font-weight: normal;
      letter-spacing: .05em;
      padding-top: calc(40/375*100)vw;
    }

    &-links {
      margin-top: calc(20/375*100)vw;

      a {
        display: block;
        color: var(--text-primary);
      }
    }

    &-text {
      padding: calc(20/375*100)vw;
      background-color: var(--bgcolor-secondary);

      &-title {
        font-size: calc(18/375*100)vw;
        /*font-family: var(--font-tsukushi);*/
    font-family: "Helvetica Neue",Arial,"Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
        margin-top: .5em;
        line-height: 1.333333333;
      }

      &-description {
        margin-top: 1em;
      }

      &-button {
        margin-top: calc(15/375*100)vw;
        @mixin m-more-btn-small-hover var(--button-text-hover), var(--color-primary);
        width: calc(140/375*100)vw;
        text-align: center;
        /* padding: calc(9/375*100)vw calc(20/375*100)vw; */

        span {
          font-family: var(--font-apex-book);
          letter-spacing: .1em;
        }

        i {
          right: 5%;
        }
      }
    }

    &-image {
      img {
        width: 100%;
        height: auto;
      }
    }

    .u-product-variation-buttons {
      padding: 0 calc(20/375*100)vw;
    }
  }

  &-slide-arrow {
    position: absolute;
    top: 50%;
    margin-top: calc(-15/375*100)vw;
    line-height: 0;
    z-index: 99;

    svg {
      fill: var(--color-primary);
      width: calc(16/375*100)vw;
      height: calc(29/375*100)vw;
    }
  }

  &-prev-arrow {
    svg {
      transform: rotate(180deg);
    }
  }

  /* otherlineup */
  &-otherlineup {
    padding: calc(40/375*100)vw calc(20/375*100)vw calc(40/375*100)vw;

    &-title {
      text-align: center;
      font-family: var(--font-apex-medium);
      font-size: calc(32/375*100)vw;
      font-weight: normal;
      letter-spacing: .05em;
    }

    &-lineup {
      width: calc(255/375*100)vw;
      margin: 0 auto;
      position: relative;

      a {
        color: var(--text-primary);
      }
    }

    .site-pageheader-spnavi-models-model {
      border-bottom: none;
    }

    .site-pageheader-spnavi-models-model-image {
      text-align: center;
    }

    .site-pageheader-spnavi-models-model-image img {
      width: 90%;
      display: inline-block;
    }

    .u-product-prev-arrow {
      left: calc(-20/375*100)vw;
    }

    .u-product-next-arrow {
      right: calc(-20/375*100)vw;
    }
  }

  /* mondoalfa */
  &-mondoalfa {
    background-color: var(--bgcolor-secondary);
    padding: calc(20/375*100)vw 0 calc(60/375*100)vw;

    &-title {
      img {
        width: calc(240/375*100)vw;
      }

      &-cover {
        text-align: center;
        padding: calc(30/375*100)vw;
        border-bottom: 2px solid var(--color-primary);
      }
    }

    &-lead {
      margin-top: 1em;
    }

    &-morebutton {
      margin: calc(30/375*100)vw calc(50/375*100)vw 0;

      &-button {
        @mixin m-more-btn-large-hover;
        width: 100%;
        font-family: var(--font-apex-medium);
        letter-spacing: .1em;
      }
    }

    &-news {
      margin-top: calc(40/375*100)vw;
      padding: 0 calc(20/375*100)vw;

      a {
        color: var(--text-primary);
      }

      .u-news-article .u-news-content:last-child {
        border-bottom: none;
      }

      .u-news-content-btn {
        @mixin m-more-btn-small-hover var(--color-primary), transparent;
        margin-top: calc(15/375*100)vw;
        width: calc(125/375*100)vw;
        font-size: calc(12/375*100)vw;
        text-align: left;

        span {
          font-family: var(--font-apex-book);
          letter-spacing: .1em;
        }
      }
    }
  }

  &-pagekv {
    margin-top: calc(60/375*100)vw;
    position: relative;

    &-kv {
      height: calc(280/375*100)vw;
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      padding: calc(20/375*100)vw;
      background-color: var(--text-primary);
      background-size: cover;
    }

    &-title {
      color: var(--color-primary);
      font-weight: bold;
    }

    &-catch {
      /*font-family: var(--font-tsukushi);*/
    font-family: "Helvetica Neue",Arial,"Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
      color: var(--button-text-hover);
      font-size: calc(20/375*100)vw;
    }
  }

  &-cover {
    margin: calc(20/375*100)vw calc(20/375*100)vw calc(60/375*100)vw;

    .u-unckernavi {
      display: block;
      margin-right: calc(-20/375*100)vw;

      ul {
        @mixin m-flexset flex-start;
        width: 100%;
        overflow-x: scroll;

      }

      li {
        margin-right: calc(20/375*100)vw;
        white-space: nowrap;
      }

      a {
        color: var(--text-primary);
        display: block;
        background: url(../image/icon-arrow-dwon-red.svg) center 80% no-repeat;
        padding: 0 0 calc(15/375*100)vw;
        transition: all var(--base-animespeed);
      }
    }
  }

  &-section {
    padding: calc(40/375*100)vw 0;

    &-sectiontitle {
      font-size: calc(20/375*100)vw;
      font-family: var(--font-tsukushi);
      font-weight: normal;
    }

    &-lead {
      margin-top: 1em;
      font-family: var(--font-tsukushi);
      font-feature-settings: "palt"1;
      line-height: 1.5625;
      &-title {
        margin-top: 1em;
        font-family: var(--base-font);
        font-weight: bold;
      }
      &-notice {
        font-size: calc(12/375*100)vw;
        margin-top: 2em;
      }
    }

    &-heading {
      /*font-family: var(--font-tsukushi);*/
    font-family: "Helvetica Neue",Arial,"Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
      font-weight: normal;
      font-size: calc(18/375*100)vw;
    }
    &-note {
      font-size: calc(14/375*100)vw;
    }
  }

  &-slick {
    &-primary {
      margin: calc(20/375*100)vw calc(-20/375*100)vw 0;
      position: relative;

      &-content {
        width: calc(255/375*100)vw;
        padding: 0 calc(20/375*100)vw;
      }

      .u-product-prev-arrow {
        left: calc(30/375*100)vw;
        top: calc(90/375*100)vw;
      }

      .u-product-next-arrow {
        right: calc(30/375*100)vw;
        top: calc(90/375*100)vw;
      }

      &-imagebox {
        position: relative;

        img {
          width: 100%;
        }

        span {
          color: var(--button-text-hover);
          font-family: var(--font-apex-book);
          position: absolute;
          bottom: calc(5/375*100)vw;
          right: calc(10/375*100)vw;
          font-size: calc(10/375*100)vw;
          text-shadow: 1px 1px 1px #000;
        }
      }

      &-text {
        margin-top: 1em;
        strong {
          display: block;
        }
      }

      &-thumbnail {
        display: none;
      }
    }

    &-secondary {
      margin: calc(20/375*100)vw calc(-20/375*100)vw 0;
      position: relative;

      line-height: 1.375;

      &-content {
        width: calc(255/375*100)vw;
        padding: 0 calc(20/375*100)vw;
      }

      .u-product-prev-arrow {
        left: calc(30/375*100)vw;
        top: calc(90/375*100)vw;
      }

      .u-product-next-arrow {
        right: calc(30/375*100)vw;
        top: calc(90/375*100)vw;
      }

      &-imagebox {
        position: relative;
        img {
          width: 100%;
        }
        span {
          color: var(--button-text-hover);
          font-family: var(--font-apex-book);
          position: absolute;
          bottom: calc(5/375*100)vw;
          right: calc(10/375*100)vw;
          font-size: calc(10/375*100)vw;
          text-shadow: 1px 1px 1px #000;
        }
      }
      &-title {
        margin-top: 1em;
        font-weight: bold;

        &02 {
          margin-top: calc(30/375*100)vw;
        }
      }

      &-text {
        margin-top: 1em;
      }
    }

    &-tertiary {
      &-list {
        &-detail {
          &-tag {
            list-style: none;
            font-family: var(--font-apex-medium);
            font-size: calc(9/375*100)vw;
            margin: calc(5/375*100)vw 0 calc(10/375*100)vw;;
            letter-spacing: .1em;
            text-transform: uppercase;
            li {
              border: 1px solid var(--text-primary);
              border-radius: calc(3/375*100)vw;
              display: inline-block;
              padding: calc(5/375*100)vw calc(10/375*100)vw calc(1/375*100)vw;
              line-height: 1;
            }
          }
          &-text {
            background-color: var(--bgcolor-primary);
            margin: calc(-20/375*100)vw auto 0;
            padding: calc(20/375*100)vw calc(20/375*100)vw 0;
            position: relative;
            width: calc(278/375*100)vw;
            p {
              font-feature-settings: "palt"1;
              line-height: 1.375;
              font-family: var(--font-tsukushi);
            }
          }
        }
      }
      &-btncover {
        text-align: center;
        margin-top: calc(15/375*100)vw;
        &-btn {
          @mixin m-more-btn-small-hover var(--color-primary), var(--button-text-hover);
          width: calc(125/375*100)vw;
          font-size: calc(12/375*100)vw;

          span {
            font-family: var(--font-apex-book);
            letter-spacing: .1em;
          }
        }
      }
      a {
        color: var(--text-primary);
      }
      .u-next-arrow {
        right: -2%;
      }
      .u-prev-arrow {
        left: -2%;
      }
      .u-next-arrow svg,
      .u-prev-arrow svg {
        fill: rgba(0, 0, 0, .5);
      }
      .slick-dots svg {
        fill: #D1D1D1;
      }
      .slick-dots .slick-active svg {
        fill: #fff;
      }
      .slick-slide {
        padding-bottom: calc(80/375*100)vw;
      }
    }
  }

  &-moviebox {
    margin-top: calc(60/375*100)vw;

    a {
      color: var(--text-primary);
    }

    &-link {
      display: block;
    }

    &-imagebox {
      position: relative;

      img {
        width: 100%;
      }

      &::after {
        content: '';
        background: url(../image/icon-play.svg) center no-repeat;
        background-size: calc(51/375*100)vw;
        width: calc(51/375*100)vw;
        height: calc(51/375*100)vw;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: calc(-25.5/375*100)vw 0 0 calc(-25.5/375*100)vw;
      }
    }

    &-textbox {
      padding: calc(20/375*100)vw calc(10/375*100)vw 0;
    }

    &-title {
      font-size: calc(18/375*100)vw;
      font-family: var(--font-tsukushi);
      padding-bottom: 1em;
      position: relative;

      &::before {
        content: '';
        height: 1px;
        width: calc(60/375*100)vw;
        background-color: var(--color-primary);
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }

    &-name {
      margin-top: 1.2em;
      font-family: var(--font-apex-book);
    }

    &-kana {
      font-size: calc(12/375*100)vw;
    }

    &-position {
      margin-top: 1.5em;
      font-size: calc(12/375*100)vw;
    }
  }

  &-widthpicture {
    margin: calc(20/375*100)vw calc(-20/375*100)vw 0;
    display: block;
    line-height: 0;

    img {
      width: 100%;
      height: auto;
    }
  }

  &-picturelist {
    list-style: none;

    li {
      line-height: 0;
      margin-top: calc(20/375*100)vw;
    }

    img {
      width: 100%;
    }
  }


  &-safemovie {
    margin: calc(30/375*100)vw calc(-20/375*100)vw 0;

    a {
      color: var(--text-primary);
    }

    &-link {
      display: block;
    }
    &-imagebox {
      line-height: 0;
      position: relative;

      .u-product-safemovie-link & {
        position: relative;

        &::after {
          content: '';
          background: url(../image/icon-play.svg) center no-repeat;
          background-size: calc(51/375*100)vw;
          width: calc(51/375*100)vw;
          height: calc(51/375*100)vw;
          position: absolute;
          top: 50%;
          left: 50%;
          margin: calc(-25.5/375*100)vw 0 0 calc(-25.5/375*100)vw;
        }
      }

      picture {
        display: block;
      }

      img {
        width: 100%;

      }

      .u-product-noticebox {
        @mixin m-absolute auto,1em,.8em,auto;
        @mixin m-flexset flex-start,nowrap;
        color: #fff;
        line-height: 1.4;
      }
    }

    &-textbox {
      background-color: var(--bgcolor-secondary);
      padding: calc(20/375*100)vw;
    }

    &-title {
      font-size: calc(18/375*100)vw;
      /*font-family: var(--font-tsukushi);*/
    font-family: "Helvetica Neue",Arial,"Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
    }

    &-text {
      margin-top: 1em;
      .u-product-safemovie-unlink & {
        margin-bottom: auto;
      }
    }

    &-button {
      @mixin m-more-btn-small-hover var(--button-text-hover),var(--color-primary);
      margin-top: 1em;
      width: calc(140/375*100)vw;
      font-family: var(--font-apex-book);
      letter-spacing: .1em;
      text-align: center;
      i {
        right: 5%;
      }
      &-wide {
        @mixin m-more-btn-small-hover var(--button-text-hover),var(--color-primary);
        margin-top: 1em;
        width: calc(180/375*100)vw;
        font-family: var(--font-apex-book);
        letter-spacing: .1em;
        text-align: center;
        i {
          right: 5%;
        }
      }
    }
  }

  &-noticebox {
    color: #404040;
    font-size: calc(14/375*100)vw;
    &-red {
      color: red;
    }
    ul {
      margin-top: 2em;
    }
    li {
      list-style: none;
    }
  }

  &-twoconts {
    @mixin m-flexset flex-start,wrap;
    align-items: flex-end;
    margin-top: 2em;
    ul {margin: 0;}
    li {
      @mixin m-flexset flex-start,nowrap;
      &:before {
        content: "・";
        margin-right: .3em;
      }
    }
    &-title {
      font-weight:bold;
      font-size: 100%;
      margin-bottom: .5em;
      &:before {
        display: none;
      }
    }
  }
  &-threeconts {

    &-image {
      margin-top: calc(20/375*100)vw;

      img {
        width: 100%;
        height: auto;
      }
    }

    &-text {
      font-weight: bold;
      margin-top: .5em;
    }
  }

  &-spec {
    &-table {
      margin-top: calc(20/375*100)vw;
      font-size: calc(12/375*100)vw;
      line-height: 1.75;
      .special {
        color: red;
      }

      table {
        min-width: 100%;
      }

      thead {
        th {
          color: var(--text-primary);
          border-bottom: 2px solid var(--table-head-bordercolor);
          text-align: left;
          padding: calc(15/375*100)vw calc(15/375*100)vw calc(15/375*100)vw 0;
          white-space: nowrap;
          font-weight: bold;
          font-family: var(--font-apex-book-m);
          vertical-align: bottom;
          small {
            margin-top: 0;
          }

          &:first-child {
            position: sticky;
            top: 0;
            left: 0;
            z-index: 3;
            background-color: #fff;
          }
        }
      }

      tbody {
        th {
          border-bottom: 1px solid var(--table-body-bordercolor);
          padding: calc(8/375*100)vw calc(8/375*100)vw calc(8/375*100)vw 0;
          vertical-align: middle;
          text-align: left;
          font-weight: normal;
          position: sticky;
          top: 0;
          left: 0;
          z-index: 3;
          background-color: #fff;

          &.sub_th {
            position: static;
          }
        }

        td {
          border-bottom: 1px solid var(--table-body-bordercolor);
          padding: calc(8/375*100)vw calc(8/375*100)vw calc(8/375*100)vw 0;
          vertical-align: middle;
          text-align: left;
        }
      }

      &-equipment {
        thead {
          th {
            text-align: center;
            width: 130px;
          }
        }

        &-title {
            text-align: left !important;
            width: 400px !important;

        }



        tbody {

          th {
            .indent {
              display: block;
              margin-left: 2em;
            }
          }
          td {
            text-align: center;
          }
        }

      }

      &-colors {

        &-title {
          font-weight: bold;
        }
      }

      &-colors1 {
        margin: 0 calc(-20/375*100)vw 0;
        padding: calc(20/375*100)vw calc(20/375*100)vw 0;

        table {
          width: 100%;
          border-top: 1px solid var(--table-body-bordercolor);
          margin-top: calc(5/375*100)vw;
        }

        tbody tr:first-child th:first-child {
          width: calc(110/375*100)vw;
        }
        th {
          border-right: 1px solid var(--table-body-bordercolor);
        }
        th + td {
          padding-left: calc(10/375*100)vw;
        }

        img {
          width: calc(90/375*100)vw;
        }
        tbody tr:last-child {
          th, td {
            border-bottom: none;
          }
        }
      }
      &-colors2 {
        margin: 0 calc(-20/375*100)vw 0;
        padding: calc(20/375*100)vw calc(20/375*100)vw 0;
        border-top: 2px solid var(--table-head-bordercolor);

        table {
          width: 100%;
          border-top: 1px solid var(--table-body-bordercolor);
          margin-top: calc(5/375*100)vw;
        }

        tbody tr:first-child th:first-child {
          width: calc(110/375*100)vw;
        }
        tbody th {
          border-right: 1px solid var(--table-body-bordercolor);
          text-align: center;
        }
        table tbody td {
          text-align: center;
        }
        th + td {
          padding-left: calc(10/375*100)vw;
        }

        img {
          width: calc(90/375*100)vw;
        }
      }

      &-colors3 {
        border-top: 2px solid var(--table-head-bordercolor);
        border-bottom: 2px solid var(--table-head-bordercolor);
        margin: calc(20/375*100)vw calc(-20/375*100)vw;


        tbody th.u-product-spec-table-colors3-tabletitle {
          font-weight: bold;
        }

        .sp-hideth {
          display: none;
        }

        .bordertr {
          border-top: 2px solid var(--table-head-bordercolor);
        }

        tr {
          th {
            width: calc(120/375*100)vw;
            padding: calc(5/375*100)vw calc(10/375*100)vw calc(5/375*100)vw calc(20/375*100)vw;
            border-right: 1px solid var(--table-body-bordercolor);

            img {
              width: calc(80/375*100)vw;
            }
          }

          &.sp-nobr {
            td {
              white-space: nowrap;
              padding: calc(5/375*100)vw calc(10/375*100)vw;
            }
          }

          &.bodycolor {
            th {
              text-align: center;
              border-right: 1px solid var(--table-body-bordercolor);
            }
            td {
              border-left: 1px solid var(--table-body-bordercolor);
              padding: calc(5/375*100)vw calc(10/375*100)vw;
              white-space: nowrap;
            }
          }

          td {
            text-align: center;

            &.bordertd {
              border-left: 1px solid var(--table-body-bordercolor);

              &-dark {
                border-left: 1px solid var(--table-head-bordercolor);
              }
            }

            img {
              width: calc(90/375*100)vw;
            }
          }
        }
      }
    }
    &-limited {
      margin-top: calc(35/375*100vw);
      &-model {
        list-style: none;
        font-size: calc(12/375*100vw);
        font-family: var(--font-apex-book-m);
        margin-bottom: calc(15/375*100)vw;
        font-weight: bold;
        text-align: center;
        @mixin m-flexset;
          li{
            width: calc(225/375*100)vw;
            &:first-child {
              width: calc(110/375*100)vw;
            }
          }
      }
      &-interior {
        margin: 0;
        padding: calc(20/375*100)vw 0 0;
        border-top: 2px solid var(--table-head-bordercolor);
        table {
          width: 100%;
          border-top: 1px solid var(--table-body-bordercolor);
          margin-top: calc(5/375*100)vw;
          tbody {
            tr:first-child th:first-child {
              width: calc(110/375*100)vw;
            }
            td {
              text-align:center;
            }
            tr:last-child {
              th, td {
                border-bottom: none;
              }
            }
          }
          th {
            border-right: 1px solid var(--table-body-bordercolor);
          }
          th + td {
            padding-left: calc(10/375*100)vw;
          }
        }
        img {
          width: calc(90/375*100)vw;
        }
      }
      &-bodycolor {
        margin: 0;
        padding: calc(20/375*100)vw 0 0;
        border: solid;
        border-width: 2px 0 1px;
        border-color: var(--table-head-bordercolor) transparent var(--table-body-bordercolor);

        table {
          width: 100%;
          border-top: 1px solid var(--table-body-bordercolor);
          margin-top: calc(5/375*100)vw;

          tbody {
            th {
              border-right: 1px solid var(--table-body-bordercolor);
              text-align: center;
              width: calc(110/375*100)vw;
            }
            td {
              text-align: center;
            }
            tr:last-child {
              th, td {
                border-bottom: 0;
              }
            }
          }
          th + td {
            padding-left: calc(10/375*100)vw;
          }
        }


        img {
          width: calc(90/375*100)vw;
        }
      }
    }
    &-caution {
      margin-top: calc(60/375*100)vw;
    }
  }

  &-pricelist {
    width: calc(270/375*100)vw;
    margin: 0 auto;

    &-content {
      width: calc(270/375*100)vw;
      margin: 0 auto;
      position: relative;
      text-align: center;
    }
    .u-product-prev-arrow {
      left: calc(-20/375*100)vw;
    }

    .u-product-next-arrow {
      right: calc(-20/375*100)vw;
    }

    &-image {
      img {
        width: 100%;
      }
    }

    &-limit {
      font-size: calc(12/375*100vw);
      font-weight: bold;
      margin-bottom: 1em;
    }
    &-name {
      font-family: var(--font-apex-medium);
      margin-bottom: calc(5/375*100vw);
    }
    &-pricekibo {
      font-size: calc(12/375*100)vw;
    }
    &-price {
      font-size: calc(14/375*100)vw;

      span {
        font-size: calc(16/375*100)vw;
        font-family: var(--font-apex-book);
      }
    }
    &-pricezei {
      font-size: calc(12/375*100)vw;
      span {
        font-family: var(--font-apex-book);
      }
    }
    &-recyclea {
      font-size: calc(12/375*100)vw;
      margin-top: 1em;
    }
    &-recycleb {
      font-size: calc(14/375*100)vw;

      span {
        font-size: calc(16/375*100)vw;
        font-family: var(--font-apex-book);
      }
    }
    .u-product-noticebox {
      margin-top: 1em;
    }
  }
}
