/* @font-face {
    font-family: 'ApexNew-BoldItalic';
    font-style: normal;
    font-weight: normal;
    src: local('ApexNew-BoldItalic'), url('/common/fonts/ApexNew-BoldItalic.woff') format('woff');
}
@font-face {
    font-family: 'ApexNew-Thin';
    font-style: normal;
    font-weight: normal;
    src: local('ApexNew-Thin'), url('/common/fonts/apexnew-thin.woff') format('woff');
}
@font-face {
    font-family: 'ApexNew-ThinItalic';
    font-style: normal;
    font-weight: normal;
    src: local('ApexNew-ThinItalic'), url('/common/fonts/apexnew-thinitalic.woff') format('woff');
} */
@font-face {
  font-family: 'Sequel';
	font-style: normal;
	font-weight: normal;
	src: local('Sequel'),
	url('/common/fonts/Sequel-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'ApexNew-Light';
    font-style: normal;
    font-weight: normal;
    src: local('ApexNew-Light'),
      url('/common/fonts/ApexNew-Light.woff') format('woff'),
      url('/common/fonts/ApexNew-Light.ttf') format('truetype');
}
@font-face {
    font-display: swap;
    font-family: 'ApexNew-Book';
    font-style: normal;
    font-weight: normal;
    src: local('ApexNew-Book'), url('/common/fonts/ApexNew-Book.woff') format('woff');
}
@font-face {
  font-display: swap;
  font-family: 'ApexNew-BookItalic';
  font-style: normal;
  font-weight: normal;
  src: local('ApexNew-BookItalic'), url('/common/fonts/ApexNew-BookItalic.woff') format('woff');
}
@font-face {
    font-display: swap;
    font-family: 'ApexNew-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('ApexNew-Medium'), url('/common/fonts/ApexNew-Medium.woff') format('woff');
}
/* @font-face {
    font-family: 'ApexNew-MediumItalic';
    font-style: normal;
    font-weight: normal;
    src: local('ApexNew-MediumItalic'), url('/common/fonts/apexnew-mediumitalic.woff') format('woff');
} */
 @font-face {
    font-display: swap;
    font-family: 'ApexNew-Bold';
    font-style: normal;
    font-weight: normal;
    src: local('ApexNew-Bold'), url('/common/fonts/ApexNew-Bold.woff') format('woff');
}
/* @font-face {
    font-family: 'ApexNew-Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('ApexNew-Heavy'), url('/common/fonts/apexnew-heavy.woff') format('woff');
}
@font-face {
    font-family: 'ApexNew-HeavyItalic';
    font-style: normal;
    font-weight: normal;
    src: local('ApexNew-HeavyItalic'), url('/common/fonts/apexnew-heavyitalic.woff') format('woff');
}
@font-face {
    font-family: 'ApexNew-Ultra';
    font-style: normal;
    font-weight: normal;
    src: local('ApexNew-Ultra'), url('/common/fonts/apexnew-ultra.woff') format('woff');
}
@font-face {
    font-family: 'ApexNew-UltraItalic';
    font-style: normal;
    font-weight: normal;
    src: local('ApexNew-UltraItalic'), url('/common/fonts/apexnew-ultraitalic.woff') format('woff');
} */

@font-face {
  font-display: swap;
  font-family: 'alfa-icons';
  src: url("/common/fonts/alfa-icons.eot");
  src: url("/common/fonts/alfa-icons.eot") format("embedded-opentype"), url("/common/fonts/alfa-icons.ttf") format("truetype"), url("/common/fonts/alfa-icons.woff") format("woff"), url("/common/fonts/alfa-icons.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}